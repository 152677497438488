export const ERROR = {
  EMAIL_VALIDATION: "Enter valid email.",
  EMPTY_PASSWORD: "Password should not be empty.",
  PASSWORD_LENGTH: "Password should be > than 7.",
  CONFORM_EMPTY: "Confirm password should not be empty.",
  SAME_ERROR: "New password and confirm password should be same.",
};


export const ERROR_MSGS = {
  EMAIL_EMPTY: "Email can not be empty!",
  USER_NOT_EXIST: "User does not exist",
  USER_EXIST: "User already exist",
  VALID_EMAIL: "Please Enter Correct Email",
  FIRST_NAME_EMPTY: "First Name can not be empty!",
  LAST_NAME_EMPTY: "Last Name can not be empty!",
  AVATAR_EMPTY: "Please upload a profile picture",
  PASSWORD_EMPTY: "Password can not be empty!",
  NEW_PASSWORD_EMPTY: "New Password can not be empty!",
  CONFIRM_PASSWORD_EMPTY: "Confirm Password can not be empty!",
  PASSWORD_MISMATCH: "Password does not match",
  PASSWORD_LENGTH: "Password must be greater than 8 characters",
  PHONE_EMPTY: "Phone can not be empty"
};