import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import Chatbot from "./components/chat/ChatBox";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Chatbot />
      <ToastContainer
        transition={Slide}
        autoClose={1500}
        hideProgressBar
        draggable
      />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
