import React from 'react'

const TagsLine = ({ icon, typeText, tagText, price, smallDes }) => {
    return (
        <div className={`flex items-center gap-2 py-3 ${smallDes && '!items-start '}`}>
            {icon}
            <div>{typeText}</div>
            {price ? <div className={`ml-5 text-[#4F46E5] text-lg font-semibold ${smallDes && '!mt-0 pt-0 leading-none'}`}>{price}<span className={'text-sm text-black font-normal'}>{smallDes ? smallDes : '/Month'} </span></div> :
                <div className="text-xs ml-4 bg-gray-200 text-black rounded-sm w-fit py-1 px-2">{tagText}</div>
            }
        </div>
    )
}

export default TagsLine