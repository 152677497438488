import { convertToDollars } from "../../utils/helpers";

const PlanCard = ({ item, selectedPlan, index, onClick, plans }) => {
  // const data = JSON.parse(dataArray
  // const data = [
  const data1 = ["Career Snapshot", "Skill Gap Analysis", "Learning Ladder", "Trends and Technologies"]
  const data2 = ["Everything in previous plan", "Mentorship and Guidance", "Certifications & Qualifications", "Transition Trajectories", "Project Playbook", "Network Nexus", "Work-Life Integration", "Charity Champions", "Employability Empowerment", "Market Movers", "Blueprint for Success"]
  const data3 = ["Everything in previous plan", "Destiny Chatbot based on GPT-4"]
  // ]
  return (
    <div
      onClick={onClick}
      className={`border  bg-white shadow-inputShadow relative cursor-pointer rounded-xl pb-4 px-5 w-full  min-h-full md:min-w-[200px] md:w-fit`}
    >
      <button className="bg-slate-200 rounded-xl  px-3 py-1 text-black text-xs absolute right-0 left-0 z-10 w-fit -top-3 mx-auto font-semibold ">
        {item?.product_name}
      </button>
      <div className=" py-4">
        <div className="flex justify-center mt-2 mb-3">
        </div>
        <div className="flex justify-center items-center">
          <span className="text-2xl font-semibold">
            ${convertToDollars(item?.unit_amount)}
          </span>
        </div>
        <div className="mt-3 text-gray-500 text-xs">{item?.pageInfo}</div>
      </div>
      <ul className="list-disc px-3 text-sm whitespace-nowrap text-primary">
        {plans && index === 0 && data1.map((item, index2) =>
        (
          <li>{item}</li>
        ))}
        {plans && index === 1 && data2.map((item, index2) =>
        (
          <li>{item}</li>
        ))}
        {plans && index === 2 && data3.map((item, index2) =>
        (
          <li>{item}</li>
        ))}
        {/* {JSON.parse(item.description).map((item, i) => (
              <li key={i}>{item}</li>
            ))} */}
      </ul>
    </div>
  );
};
export default PlanCard;
