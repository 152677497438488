import React, { useEffect } from 'react'
import SecondHeader from '../layout/SecondHeader'
import { Fade, Zoom } from 'react-reveal'
import Footer from '../layout/Footer'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const HomePage = () => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("user"));

  const Button = ({ text, customClass, to }) => {
    return (
      <Link to={to}>
        <button className={'py-3 px-6 rounded-full border font-semibold text-xs uppercase ' + customClass}>
          {text}
        </button>
      </Link>
    )
  }
  const early_data = [
    {
      name: 'EMMA',
      head: 'EXECUTIVE ASSISTANT TO GRAPHIC DESIGNER',
      description: "Myaidestiny made my switch to graphic design clear and achievable. It’s a game-changer!"
    },
    {
      name: 'MIKE',
      head: 'ACCOUNTANT TO SOFTWARE DEVELOPER',
      description: "Thanks to Myaidestiny, I’m on my way from accounting to coding. Absolutely transformative!"
    },
    {
      name: 'SOPHIA',
      head: 'TEACHER TO DIGITAL MARKETING SPECIALIST',
      description: "Myaidestiny guided my leap from teaching to digital marketing. Invaluable and empowering!"
    }
  ]
  const PICTURE_STEPS = [
    {
      picture: '/images/home_2.svg',
      head_1: 'Your Custom',
      head_2: 'Career Compass',
      description: 'Navigate your career transition with AI-powered reports tailored to your unique journey. Discover the most effective routes from your current role to your dream job.'
    },
    {
      picture: '/images/home_3.svg',
      head_1: 'Skills And Learning',
      head_2: 'Pathways',
      description: 'Unlock your potential with curated learning resources designed to bridge your skills gap. Dive into personalized courses and certifications that align with your career aspirations.'
    },
    {
      picture: '/images/home_4.svg',
      head_1: 'Networking And',
      head_2: 'Mentorship',
      description: 'Expand your professional network and find mentorship with industry insiders to accelerate your career path. Leverage these connections to gain insights, opportunities, and guidance on your journey.'
    }
  ]
  useEffect(() => {
    if (location.search.includes('true')) {
      toast.success("Account Verified!")
    }
  }, [location])
  return (
    <>
      <SecondHeader user={user} />
      <section className='pt-[52px]  text-center !leading-[1.2rem]'>
        <Zoom duration={1000}>
          <div className=' text-2xl md:text-7xl font-semibold text-lineBlack'>
            Transform Your Path:
          </div>
        </Zoom>
        <Zoom top duration={2000}>
          <div className='font-semibold text-2xl md:text-7xl text-primary  mt-2'>
            Your AI-Driven Career Report
          </div>
        </Zoom>
      </section>
      <section className='py-16 flex flex-col items-center gap-10'>
        <Fade duration={1000} top>
          <div className='text-secondary text-base text-center leading-6 font-medium'>
            Discover AI-Driven Career Insights and Tailored Action Plans to Navigate your <br /> Next Career Switch
          </div>
        </Fade>
        <Fade duration={2000}>
          <Button to={user?.access ? 'dashboard' : 'signup'} text={user?.access ? "Dashboard" : "Try FOR FREE"} customClass="border-none w-fit border-black bg-black text-white" />
        </Fade>
      </section>
      <section className='pb-20'>
        <Fade duration={2000}>
          <img src="/images/home_1.svg" className='w-[70%] mx-auto' alt="" />
        </Fade>
      </section>
      <section className='pb-20'>
        <div className='text-center text-xl text-[#858694]'>
          Early Testers Weigh In: Their Myaidestiny Experiences
        </div>
        <div className='flex flex-col px-5 md:px-0 pt-10 md:flex-row gap-8 md:w-[90%] lg:w-[65%] mx-auto' >
          {early_data.map((item, index) => (
            <Fade bottom>
              <div key={index} className='p-8 mx-auto bg-[#e6eaf2] rounded-lg'>
                <div className='leading-[12px] font-medium uppercase text-xs'>
                  {item.name}
                </div>
                <div className='text-[#858593] font- uppercase text-xs'>
                  {item.head}
                </div>
                <div className='text-[#858593] leading-[1.2rem] font-base pt-6'>
                  {item.description}
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </section>
      <section className='pb-20 flex flex-col gap-10 md:gap-32'>
        {PICTURE_STEPS.map((item, index) => (
          <div key={index} className={`w-full px-5 lg:px-0 lg:w-[65%] mx-auto flex flex-col  text-center md:text-left items-center gap-20  ${index % 2 ? 'md:flex-row-reverse' : "md:flex-row"}`}>
            <Zoom >
              <img src={item.picture} className='w-full md:w-[400px] lg:w-[37rem]' alt="" />
            </Zoom>
            <Zoom bottom >
              <div>
                <div className='text-4xl font-semibold text-lineBlack'>
                  {item.head_1}
                </div>
                <div className='whitespace-nowrap font-semibold text-4xl text-primary  mt-2'>
                  {item.head_2}
                </div>
                <div className='text-[#858593] leading-[1.2rem] font-base pt-6'>
                  {item.description}
                </div>
              </div>
            </Zoom>
          </div>
        ))}
      </section>
      <section className='py-10 px-5 flex flex-col gap-8 items-center'>
        <Fade bottom >
          <div className='text-4xl font-semibold text-lineBlack text-center'>
            Ready to Change Your Future?
          </div>
          <div className='text-center'>
            Discover, connect, and evolve with Myaidestiny's bespoke career planning tools. Let's <br /> unlock your potential together.
          </div>

          <Button text={user?.access ? "Dashboard" : "Try it FOR FREE"}
            to={user?.access ? 'dashboard' : 'signup'} customClass="border-none w-fit border-black bg-[#e6eaf2] text-black" />
        </Fade>
      </section>
      <Footer />
    </>
  )
}

export default HomePage