import React from "react";
import SecondHeader from "../layout/SecondHeader";
import Footer from "../layout/Footer";
import { Link } from "react-router-dom";
import { Zoom } from "react-reveal";

const card = [
    {
        heading: "Basic",
        stat: "Free",
        list: [
            "Limited report view",
            "1 free Transition Trajectory",
            "Personal use only",
        ],
        buttonText: "try for free",
    },
    {
        heading: "Career Switcher",
        stat: "$9.99",
        list: ["1 report credit", "Full comprehensive report", "Commercial Use"],
        buttonText: "Dip your toes",
    },
    {
        heading: "Serious Switcher",
        stat: "$24.99",
        list: ["Everything from Career Switcher", "3 Report Credits"],
        buttonText: "try for free",
    },
];
const Pricing = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const Button = ({ text, customClass, to }) => {
        return (
            <Link to={to}>
                <button
                    className={
                        "py-4 px-8 w-full bg-[#se6eaf2] rounded-full border border-[#00021829] font-semibold text-xs uppercase " +
                        customClass
                    }
                >
                    {text}
                </button>
            </Link>
        );
    };
    return (
        <>
            <SecondHeader user={user} />
            <div className="blue-b  mb-20">
                <Zoom>
                    <div className="text-center my-12 text-[88px] font-semibold leading-[95px]">
                        Pricing
                    </div>
                </Zoom>
                <div className=" mx-auto w-fit flex flex-wrap  lg:flex-nowrap px-5 gap-10 ">
                    {card.map((item, index) => {
                        return (
                            <Zoom duration={index === 1 ? 900 : 1500} bottom>

                                <div
                                    className={`p-8 min-w-[310px] w-full justify-beteen bg-[#e6eaf2] flex rounded-[20px] flex-col gap-4 text-[#000218] ${index === 1 && "blue-bg !text-[#e6eaf2] box-shadow"
                                        }`}
                                >
                                    <div className="text-xl font-semibold">{item.heading}</div>
                                    <div className="text-5xl">{item.stat}</div>
                                    <div className="gap-3 mt-2 flex flex-col">
                                        {item.list.map((item, arr) => (
                                            <div key={arr} className="flex items-center gap-3 leading-6 text-base">
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill={index === 1 ? 'none' : '#000'} xmlns="http://www.w3.org/2000/svg" id="svg243640223_366">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM8.5767 14L15 7.64397L13.5398 6L8.5767 10.9111L6.46018 8.81679L5 10.4608L8.5767 14Z" fill={index === 1 ? '#e6eaf2' : '#000'}></path>
                                                </svg>
                                                {item}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-auto">
                                        <Button
                                            text={item.buttonText}
                                            customClass={"!bg-[#e6eaf2] text-black"}
                                        />
                                    </div>
                                </div>
                            </Zoom>
                        );
                    })}
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Pricing;
