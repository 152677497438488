import React from "react";
import { Link } from "react-router-dom";

const SecondHeader = ({ user }) => {
    const Button = ({ text, customClass, to }) => {
        return (
            <Link to={to}>
                <button
                    className={
                        "py-3 px-6 rounded-full border font-semibold text-xs whitespace-nowrap uppercase " +
                        customClass
                    }
                >
                    {text}
                </button>
            </Link>
        );
    };
    return (
        <div>
            <div className="py-5 flex w-5/6 mx-auto justify-between items-center">
                <div>
                    <Link to={"/"}>
                        <img src="/images/site_logo.png" height={80} width={250} alt="" />
                    </Link>
                </div>
                <div className=" hidden md:flex items-center gap-2">
                    <Button to={`/`} text={"Home"} />
                    <Button to={`/report-example/?uuid=a6557dda-06f4-4bbf-aa7f-9042d9eadcd2`} text={"Example"} />
                    <Button to={'/pricing'} text={"Pricing"} />
                    <Button to={'/help'} text={"FAQ"} />
                </div>
                <div className="flex items-center gap-2">
                    {!user?.access && <Button to={'/signin'} text={"Login"} customClass="border-none" />}
                    <Button
                        text={user?.access ? "Dashboard" : "Try FOR FREE"}
                        to={user?.access ? '/dashboard' : '/signup' || '/signin'}
                        customClass="border-none border-black bg-black text-white"
                    />
                </div>
            </div>
        </div>
    );
};

export default SecondHeader;
