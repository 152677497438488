import { Link } from "react-router-dom";
import { convertToDollars } from "../../utils/helpers";
import { Zoom } from "react-reveal";

const SampleCard = ({ item }) => {
  return (
    <div className=" py-4 bg-white">
      <div className="flex justify-center mt-2 mb-3">
        <button className="bg-slate-100 shadow-inputShadow px-3 py-1 text-primary text-lg font-semibold">
          {item?.name}
        </button>
      </div>
      <div className="mt-3 text-black text-md line-clamp-6">
        {item?.description}
      </div>
    </div>
  );
};
export default SampleCard;
