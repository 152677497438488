import React from "react";

const Card = ({
  head,
  description = true,
  children,
  cardHeight,
  descriptionCss,
  icon,
  bgColorIcon,
  backgroundColor,
  label,
}) => {
  return (
    <div
      className={`card w-full max-h-full bg-red-300 px-6 py-6 ${cardHeight}`}
      style={{ backgroundColor: backgroundColor ?backgroundColor:'#fff' }}
    >
      <div className=" flex items-center  gap-3 mb-3">
        {icon && (
          <div
            className={`p-2.5 rounded-md h-fit w-fit flex flex-col items-center justify-center`}
            style={{ backgroundColor: bgColorIcon }}
          >
            {icon}
          </div>
        )}
        <div>
          {label && (
            <div className="text-[#455571] text-sm leading-[18.2px]">
              {label}
            </div>
          )}
          <div className="text-base text-[#0D182A] font-semibold leading-[26px]">
            {head}
          </div>
        </div>
      </div>
      <div
        className={`!text-[15px] leading-[22.2px] font-normal text-[#455571] ${
          !description && "pb-5"
        }`}
      >
        {description && (
          <div
            className={`!text-[15px] leading-[22.2px] font-normal text-[#455571] pb-5 ${descriptionCss}`}
          >
            {description}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Card;