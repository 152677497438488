import React, { useState } from "react";
import DashboardLayout from "../layout/DashboardLayout";
import { getUserReports, getUserTotalCredits } from "../services/auth-services";
import { useEffect } from "react";
import TableComponent from "../components/DataTable";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Button from "../components/buttons/DefaultButton";
import { Link, useNavigate } from "react-router-dom";
import { TbReportSearch } from "react-icons/tb";
import { toast } from "react-toastify";
import { BsClipboardPlusFill } from "react-icons/bs";
import { TiTick } from "react-icons/ti";
import { ImCross } from "react-icons/im";
import { Zoom } from "react-reveal";

const Reports = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  console.log("data: ", data);
  const [loading, setLoading] = useState(true);
  const handleUserCredit = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    getUserReports(user.access)
      .then((res) => {
        setData(res?.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleUserCredit();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => row?.person,
      style: {
        justifyContent: "left",
      },
      width: "200px",
    },
    {
      name: "Country",
      selector: (row) => row?.form_data.country.name,
      style: {
        justifyContent: "left",
      },
      width: "200px",
    },
    {
      name: "Gender",
      selector: (row) => row?.form_data.gender,
      style: {
        justifyContent: "left",
      },
      width: "200px",
    },
    {
      name: "Ethnicity",
      selector: (row) => row?.form_data.ethnicity,
      style: {
        justifyContent: "left",
      },
      width: "200px",
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.created_at).toLocaleString(),
      style: {
        justifyContent: "center",
      },
    },
    {
      name: "Sample Data",
      selector: (row) => (
        <div>
          {row.is_sample ? (
            <TiTick size={20} className="text-green-500" />
          ) : (
            <ImCross className="text-red-500" />
          )}
        </div>
      ),
      style: {
        justifyContent: "center",
      },
      width: "200px",
    },
    {
      name: "Action",
      selector: (row) => (
        <Link to={`/report?uuid=${row.id}`}>
          <TbReportSearch className="stroke-primary" size={20} />
        </Link>
      ),
      style: {
        justifyContent: "center",
      },
      width: "200px",
    },
  ];
  return (
    <DashboardLayout footer>
      <div className="px-5  mt-5 w-full flex flex-col  items-center justify-center min-h-[70vh] gap-10">
        <Zoom>
          <div className="text-3xl mt-6 md:mt-5 text-center text-secondary font-medium">
            Reports History
          </div>
        </Zoom>

        <div className="w-full">
          <div className="flex justify-between mx-auto">
            <div className="my-5 ml-auto w-fit ">

              <Button
                onClick={() => navigate("/create-report")}
                customClass={"text-xs"}
                text={"Create Report"}
              >
                {" "}
                <BsClipboardPlusFill size={20} />{" "}
              </Button>
            </div>
          </div>
          {loading ? (
            <Skeleton className="h-[7vh] " count={3} />
          ) : (
            data && <TableComponent {...{ data, columns }} />
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Reports;
