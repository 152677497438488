import React from "react";
import DataTable from "react-data-table-component";
const customStyles = {
  headCells: {
    style: {
      zIndex: "9999px",
      backgroundColor: "#39b791",
      color: "white",
      justifyContent: "center",
      fontWeight: "700",
      border: "0.1px solid #dbd7cc",
      borderRadius:'15px'
    },
  },
  bodyCells: {
    style: {
      // Add your custom styles for body cells here
      color: "black", // Example text color for body cells
      backgroundColor: "white", // Example background color for body cells
      border:'0px'
      // justifyContent: "center",
      // Any other styles you want to apply to body cells
    },
  },
}
const pageOption = {
  noRowsPerPage: true,
};
const TableComponent = ({
  columns,
  data,
  progressPending,
  onChangeRowsPerPage,
  paginationPerPage,
  onChangePage,
  paginationTotalRows,
  subHeaderComponent,
  id,
}) => {
  return (
    <DataTable
      id={id}
      pagination
      data={data}
      customTableClass="my-table"
      fixedHeader
      columns={columns}
      customStyles={customStyles}
      onChangePage={onChangePage}
      fixedHeaderScrollHeight="85vh"
      progressPending={progressPending}
      paginationPerPage={paginationPerPage}
      highlightOnHover
      subHeaderComponent={subHeaderComponent}
      paginationComponentOptions={pageOption}
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={onChangeRowsPerPage}
    // conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default TableComponent;
