import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import "react-loading-skeleton/dist/skeleton.css";
import SampleCard from "../components/cardDesigns/SampleReport";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";

const SampleData = [
  {
    name: "Jack",
    description:
      "Jack is a 27-year-old Black male from the United States. He holds a Project Management Professional certificate from the Project Management Institute, obtained in June 2021. His professional experience includes serving as a U.S. Marine since January 2014, specializing in conducting amphibious operations and ground combat missions. He is interested in transitioning to a role as a Business Analyst. His investment budget is between $201 and $500, and he can commit 10 hours per week to his ideal role.",
    link: "5d6a2db9-d5ec-44eb-bc84-c8da14c3fe83",
  },
  {
    name: "Charlotte",
    description:
      "Charlotte is a 32-year-old White female from the United States. She is not armed. Charlotte's educational background is not provided. She currently works as a Hotel Receptionist in the Accommodation & Food Service Activities industry, where she is responsible for guest services, check-in/out procedures, and addressing guest inquiries. Despite her current role, Charlotte aspires to work as a Data Scientist. Her investment budget ranges from $1001 to $2000, and she can commit 24 hours per week to her ideal role.",
    link: "ae26947d-c5c3-4787-a2c8-a8a9468b6fda",
  },
  {
    name: "Gustav",
    description:
      "Gustav is a 26-year-old Asian male from the United Kingdom. He is not armed. He completed his education in Business Studies at Aston University in June 2015. Gustavo has experience working in the construction industry, starting as a Bricklayer from September 2015 to September 2019, before becoming a Project Manager in September 2019, where he oversees project planning, resource allocation, and budget management. He aspires to work as an Accountant. Gustavo's investment budget exceeds $20,000, and he can commit 8 hours per week to his ideal role.",
    link: "747cc626-057a-4c57-8173-eb4f9b52e4a0",
  },
];
const SampleReports = () => {
  return (
    <DashboardLayout footer>
      <div className="flex flex-col mt-20 pb-5  items-center justify-center gap-6">
        <div>

        </div>
        <div className="pb-5 pt-3 text-2xl leading-normal tracking-[-.114px] font-semibold text-secondary text-center ">
          Example Transitions
        </div>
        <div className="w-[90%] !mx-auto flex flex-col md:flex-row items-center gap-10 justify-center  justify-items-center">
          {SampleData?.map((item, index) => (
            <Link
              to={`/report?uuid=${item.link}`}
            >
              <Zoom duration={index === 1 ? 900 : 1500} top>
                <div
                  className={`shadow-inputShadow bg-white  border border-solid cursor-pointer rounded-xl px-5 w-full`}
                >
                  <SampleCard key={index + item?.name} {...{ item }} />
                </div>
              </Zoom>
            </Link>
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default SampleReports;
