import React, { useState } from "react";
import Header from "../layout/header";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { Link, useNavigate } from "react-router-dom";
import { ERROR } from "../utils/contants";
import { regexEmail } from "../utils/helpers";
import { toast } from "react-toastify";
import { resetPassword } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";

const DEFAULT_FIELDS = {
  email: "",
};
const ResetPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
  function checkValidations() {
    if (!regexEmail(credentials.email)) {
      toast.warn(ERROR.EMAIL_VALIDATION);
      setLoading(false);
      return false;
    }
    return true;
  }
  const handleResetPassword = () => {
    setLoading(true);
    if (checkValidations()) {
      resetPassword(credentials)
        .then((res) => {
          Swal.fire({
            text: "Check your email to reset password.",
            icon: "success",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    }
  };
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="bg w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
      <div className="border rounded-2xl  border-none shadow-primaryShadow bg-opacity-20 bg-white backdrop-blur-3xl   p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
        <Link to={"/"}>
          <img src="/images/site_logo.png" height={80} width={200} className="mx-auto" alt="" />
        </Link>
        <div className="text-xl font-semibold text-black leading-[35px] text-center">
          Reset Password
        </div>
        <TextInput
          name="email"
          onChange={(e) => onChangeFields(e)}
          placeholder={"Enter your email"}
        />

        <Button
          text={
            loading ? (
              <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
            ) : (
              "Send Email"
            )
          }
          onClick={handleResetPassword}
          customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
        />
        <div className="text-black text-xs text-center">
          Have an account already?{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/signin")}
          >
            Sign in
          </span>
        </div>
      </div>

    </div>
  );
};

export default ResetPassword;
