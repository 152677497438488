import React, { useEffect, useState } from "react";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signUp } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Swal from "sweetalert2";
import { useCookies } from "react-cookie";
import { LinkedIn, googleSvg } from "../components/svg/svg";
import Register from "../entities/Register.entity";
import { ImCross } from "react-icons/im";
import { TiTick } from "react-icons/ti";

const DEFAULT_FIELDS = {
  email: "",
  password1: "",
  password2: "",
};
const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cookies, removeCookie] = useCookies(["cookieName"]);
  const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
  const [error, setError] = useState({})
  console.log('error: ', error);
  // Access the value of the cookie
  const refreshToken = cookies.refresh;
  const accessToken = cookies.access;

  useEffect(() => {
    if (refreshToken !== "undefined") {
      let data = JSON.stringify({ refresh: refreshToken, access: accessToken });
      localStorage.setItem("user", data);
      removeCookie("refresh");
      removeCookie("access");
      // window.location.href = "/";
    }
    // eslint-disable-next-line
  }, []);

  // function checkValidations() {
  //   if (!regexEmail(credentials.email)) {
  //     toast.warn(ERROR.EMAIL_VALIDATION);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password1 === "") {
  //     toast.warn(ERROR.EMPTY_PASSWORD);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password1.length < 8) {
  //     toast.warn(ERROR.PASSWORD_LENGTH);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password2 === "") {
  //     toast.warn(ERROR.CONFORM_EMPTY);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password1 !== credentials.password2) {
  //     toast.warn(ERROR.SAME_ERROR);
  //     setLoading(false);
  //     return false;
  //   }
  //   return true;
  // }
  const handleSignUp = async () => {
    try {
      new Register({ ...credentials });
      setLoading(true);
      signUp(credentials)
        .then((res) => {
          navigate("/signin");
          Swal.fire({
            title: "Account created",
            text: "Please verify your email!",
            icon: "success",
          });
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            console.log('errors: ', errors);
            Object.keys(errors).forEach((key) => {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            });
          } else {
            toast.error("Network error.");
          }
        });
    } catch (error) {
      setError(error)
      setLoading(false)
    }

  };
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };
  const isNumber = /\d/.test(credentials.password1)

  const specialCharacter = /[!@#$%^&*]/.test(credentials.password1)

  const smallNumber = /[a-z]/.test(credentials.password1)
  const bigNumber = /[A-Z]/.test(credentials.password1)
  return (
    <div className="bg w-full min-h-screen px-5 flex  flex-col justify-center items-center my-auto ">
      <div className="border rounded-2xl  border-none shadow-primaryShadow bg-opacity-20 bg-white my-10 backdrop-blur-3xl   p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">

        <Link to={"/"}>
          <img src="/images/site_logo.png" height={80} width={200} className="mx-auto" alt="" />
        </Link>
        <div className="text-2xl font-semibold leading-[35px] text-center">
          Create account
        </div>
        <TextInput
          name={"email"}
          onChange={(e) => onChangeFields(e)}
          placeholder={"Enter your email"}
          error={error.email}
        />
        <PasswordInput
          name={"password1"}
          onChange={(e) => onChangeFields(e)}
          placeholder={"Create password"}
          error={error.password1}

        />
        <div className="text-xs">
          <div className={`flex ${isNumber ? 'text-green-500' : 'text-red-500'} transition-all items-center gap-2`}>
            <div>
              {isNumber ? <TiTick /> : <ImCross />}
            </div>
            <div>Password must contain a number.</div>
          </div>
          <div className={`flex ${specialCharacter ? 'text-green-500' : 'text-red-500'} transition-all items-center gap-2`}>
            <div>
              {specialCharacter ? <TiTick /> : <ImCross />}
            </div>
            <div>
              Password must contain a special character.
            </div>
          </div>
          <div className={`flex ${smallNumber ? 'text-green-500' : 'text-red-500'} transition-all items-center gap-2`}>

            <div>
              {smallNumber ? <TiTick /> : <ImCross />}
            </div>
            <div>
              Password must contain a lowercase letter.
            </div>
          </div>
          <div className={`flex ${bigNumber ? 'text-green-500' : 'text-red-500'} transition-all items-center gap-2`}>
            <div>
              {bigNumber ? <TiTick /> : <ImCross />}
            </div>
            <div>
              Password must contain an uppercase letter.
            </div>
          </div>
          <div className={`flex ${credentials.password1.length > 8 ? 'text-green-500' : 'text-red-500'} transition-all  items-center gap-2`}>
            <div>
              {credentials.password1.length > 8 ? <TiTick /> : <ImCross />}
            </div>
            <div>
              Password must be greater than 8 characters
            </div>
          </div>
        </div>
        <PasswordInput
          onChange={(e) => onChangeFields(e)}
          name="password2"
          placeholder={"Confirm password"}
          error={error.password2}
        />
        <Button
          onClick={handleSignUp}
          text={
            loading ? (
              <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
            ) : (
              "Continue"
            )
          }
          customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
        />
        <a href={"/api/accounts/google_login/"}>
          <Button
            text={"Sign in with LinkedIn"}
            customClass={
              "!w-full !mt-2 !py-2 ml-[4.5px] !font-normal flex-row-revers !text-[12px] !bg-transparent text-blackText border-borderColor border"
            }
          >
            {LinkedIn}
          </Button>
        </a>
        <a href={"/api/accounts/google_login/"}>
          <Button
            text={"Sign in with google"}
            customClass={
              "!w-full !mt-2 !py-2 !font-normal flex-row-revers !text-[12px] !bg-transparent text-blackText border-borderColor border"
            }
          >
            {googleSvg}
          </Button>
        </a>
        <div className="flex items-center gap-4">
          <hr className="w-full" />
          <span className="text-grayColor text-xs">or</span>
          <hr className="w-full" />
        </div>
        <div className="text-[#515961s text-xs text-center">
          Have an account already?{" "}
          <span
            className="text-primary cursor-pointer"
            onClick={() => navigate("/signin")}
          >
            Sign in
          </span>
        </div>
        {/* <a href={"/api/accounts/google_login/"}>
            <Button
              text={"Sign Up with Google "}
              customClass={
                "!w-full !mt-2 !py-2 !font-normal !text-[12px] !bg-transparent text-black border-grayColor border"
              }
            >
              {googleSvg}
            </Button>
          </a> */}
      </div>
    </div>
  );
};

export default SignUp;
