
import { ERROR_MSGS } from "../utils/contants";
import { emailValidation } from "../utils/helpers";

export default class Register {

    emptyFieldValidator(field, msg) {
        if (!this[field])
            this.errors[field] = msg;
    }
    constructor(fields) {
        this.errors = {};
        this.email = fields.email
        this.password1 = fields.password1
        this.password2 = fields.password2

        this.emptyFieldValidator("email", ERROR_MSGS.EMAIL_EMPTY)

        if (!this.errors.email && !emailValidation(this.email))
            this.errors.email = ERROR_MSGS.VALID_EMAIL
        if (this.password1.length < 8)

            this.errors.password1 = ERROR_MSGS.PASSWORD_LENGTH

        if (!this.errors.password2 && this.password1 !== this.password2)
            this.errors.password2 = ERROR_MSGS.PASSWORD_MISMATCH

        if (!/\d/.test(this.password1)) {
            this.errors.password1 = "Password must contain a number."
        }
        if (!/[!@#$%^&*]/.test(this.password1)) {
            this.errors.password1 = "Password must contain a special character."
        }
        if (!/[a-z]/.test(this.password1)) {
            this.errors.password1 = ("Password must contain a lowercase letter.");
        }
        if (!/[A-Z]/.test(this.password1)) {
            this.errors.password1 = "Password must contain an uppercase letter."
        }
        this.emptyFieldValidator("password1", ERROR_MSGS.PASSWORD_EMPTY)
        this.emptyFieldValidator("password2", ERROR_MSGS.CONFIRM_PASSWORD_EMPTY)
        if (Object.keys(this.errors).length) throw this.errors;
    }
}
