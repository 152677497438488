// import Link from "next/link";

import { Link } from "react-router-dom";

const Footer = ({ lang }) => {
    const goToBtn = () => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    };

    const FOOTER_LINKS = [
        {
            name: "Privacy Policy",
            link: "https://trycareermapcom.notion.site/CareerMap-Privacy-Policy-a3fa8031f7624ecfb4185d23539e6f2d",
        },
        {
            name: "Terms and Conditions",
            link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
        },
        {
            name: "Refund Policy",
            link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
        },
    ];
    return (
        <div>
            <footer className="border-t-2 pt-8 mt-6 ">
                <div className="footer-wrapper mb-10  md:flex md:justify-center md:flex-wrap md:gap-16 px-4 md:px-10">
                    <div className="col-1">
                        <Link to={"/"}>
                            <img
                                className="w-[250px]"
                                src="/images/site_logo.png"
                                alt="Logo"
                            />
                        </Link>
                    </div>
                    <hr className="mt-6 mb-6" />
                    <div className="col-">
                        <p className="cursor-pointer text-primary text-xl font-medium mb-2.5">
                            Info
                        </p>
                        <div className="flex flex-col gap-y-1.5">
                            <div className="flex items-center ">
                                <Link to={"/pricing"}>
                                    <p className=" text-lg cursor-pointer hover:text-primary">
                                        Pricing
                                    </p>
                                </Link>
                            </div>
                            <div className="flex items-center mt-3">
                                <Link to={`/report-example/?uuid=a6557dda-06f4-4bbf-aa7f-9042d9eadcd2`}>
                                    <p className=" text-lg cursor-pointer hover:text-primary">
                                        Example
                                    </p>
                                </Link>
                            </div>
                            <div className="flex items-center mt-3">

                                <p className=" text-lg cursor-pointer hover:text-primary">
                                    Blog
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-6 mb-6" />
                    <div className="col-2">
                        <p className=" hover:text-primary cursor-pointer text-primary text-xl font-medium mb-2.5">
                            Contact
                        </p>
                        <div className="flex flex-col gap-y-1.5">
                            <div className="flex items-center ">
                                <Link to={"mailto:support@myaidestiny.com"}>
                                    <p className=" text-lg cursor-pointer hover:text-primary">
                                        Email
                                    </p>
                                </Link>
                            </div>
                            <div className="flex items-center mt-3">
                                <p className=" text-lg cursor-pointer hover:text-primary">
                                    Twitter/ X
                                </p>
                            </div>
                            <div className="flex items-center mt-3">

                                <p className=" text-lg cursor-pointer hover:text-primary">
                                    TikTok
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-6 mb-6" />
                    <div className="col-3">
                        <p className="text-primary font-medium text-lg cursor-pointer hover:text-primary">
                            Support                        </p>
                        <ul className="flex flex-col gap-y-1">
                            <Link to={'/help'}>
                                <li className="mt-2 text-lg cursor-pointer hover:text-primary">
                                    FAQ
                                </li>
                            </Link>
                        </ul>
                    </div>
                    <hr className="mt-6 mb-6" />
                    <div className="col-4">
                        <p className="text-primary font-medium text-lg cursor-pointer hover:text-primary">
                            Legals
                        </p>
                        <ul className="flex flex-col gap-y-1">
                            {FOOTER_LINKS.map((item, index) => {
                                return (
                                    <Link target="_blank" to={item.link}>
                                        <li key={index} className="mt-2 text-lg cursor-pointer hover:text-primary">
                                            {item.name}
                                        </li>
                                    </Link>
                                )
                            })}

                        </ul>
                    </div>
                    <hr className="mt-6 mb-6" />

                </div>
            </footer>
            <section className="bg-primary">
                <div className="copyright py-4 px-5 flex items-center justify-between ">
                    <div>
                        <p className="text-white text-sm">
                            © myaidestiny {new Date().getFullYear()}. All right reserved.
                        </p>
                    </div>
                    <div className="flex">

                        <img
                            onClick={goToBtn}
                            className="h-4"
                            src="/images/Vector.png"
                            alt=""
                        />

                    </div>
                </div>
            </section>
        </div>
    );
};

export default Footer;