import React from "react";
import Header from "../../layout/header";
import FromProgress from "./FromProgress";
import TextInput from "../inputs/TextInput";
import FromFooter from "./FromFooter";
import SelectInput from "../inputs/SelectInput";
import Button from "../buttons/DefaultButton";
import ModelWindow from "../../common/PopupModal";
import ExperienceFrom from "./ExperienceFrom";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { Fade } from "react-reveal";
import { IdealSvg } from "../svg/svg";

const QuestionForm = ({
  error,
  steps,
  isProgress,
  setIsProgress,
  formData,
  handleBack,
  handleNext,
  numberOptions,
  identity,
  totalSteps,
  major_level,
  handleCountryName,
  CountryName,
  price_range,
  onChangeFields,
  handleAddExperience,
  add_experience,
  handleSubmit,
  isExperiencePopup,
  setIsExperiencePopup,
  handleUpdateExperience,
  handleChangeExperience,
  onChangeIndustry,
  experienceTemp,
  handleChangeCurrentJob,
  handleDeleteExperience,
  setExperienceTemp,
  setIsEducationPopup,
  isEducationPopup,
  DEFAULT_TEMP_EDUCATION,
  setEducationTemp,
  handleChangeEducation,
  educationTemp,
  handleUpdateEducation,
  handleAddEducation,
  handleEditEducation,
  handleDeleteEducation,
  handleChangeEmployerName,
  handleChangeName,
  inputLoading,
  places,
  place,
  handleEmployerName,
  handleDegree,
  handleChangeDegree,
  degree,
  handleCountryEducation,
  gender,
  ARMED,
  weekly_hours,
  onChangeSelect,
  handleEmployerCountry,
  countriesData,
  handleInstituteCountryName,
  handleEducationCountryName,
  handleSchoolName,
  handleAdditionalCountryName,
  activity_identity,
  DEGREE,
  onChangeDegree,
  ...props
}) => {
  const countries = countriesData?.map((i) => ({
    ...i,
    label: i.name,
    value: i.name,
    id: i.country_id,
  }));
  const placesOptions = place.map((i) => ({
    ...i,
    label: i.name,
    value: i.name,
    id: i.id,
  }));

  return (
    <div>
      <Header />
      {props.isDisclaimerAgree ? (
        <div className="w-full gap-[100px] container items !pb-10 flex flex-col md:px-10 md:mx-auto md:flex-row md:h-[80vh] justify-center m-auto pt-10">
          <div className="md:block hidden">
            <div
              // src="/images/star.png"
              className="mb-[46px] h-[65px] w-[150px] object-contain"
            // alt=""
            />
            <FromProgress
              {...{ steps, isProgress, totalSteps, setIsProgress }}
            />
          </div>
          <div className="flex flex-col px-8 pb-8 mb-10 w-full md:w-[60%]">
            {isProgress === 1 ? (
              <>
                <div className="flex gap-3 items-center mb-10">
                  <div className="bg-[#F9F5FF] w-fit p-2.5 rounded-md">
                    {IdealSvg}
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="text-[#0D182A] text-[28px] leading-[31.2px] tracking-[0.56px]">
                      Ideal Career
                    </span>
                    <span className="text-secondary text-sm font-normal leading-normal tracking-[0.28px]">
                      Please fill out the below questions
                    </span>
                  </div>
                </div>
                <TextInput
                  placeholder={"Example: Charlotte Webb"}
                  name={"name"}
                  value={formData?.name}
                  onChange={onChangeFields}
                  label={"Let’s start off with your name"}
                  customClass={"pb-3 !text-[#8D8D99] "}
                  error={error.name}
                />
                <TextInput
                  name={"ideal_role"}
                  value={formData?.ideal_role}
                  onChange={onChangeFields}
                  placeholder="Example: Real estate investment analyst"
                  label={"What's your ideal role?"}
                  error={error.ideal_role}
                />
                <SelectInput
                  label="Weekly Commitment"
                  name={"weekly_commitment"}
                  numberOptions={weekly_hours}
                  value={
                    formData.weekly_commitment &&
                    formData.weekly_commitment + " hours per week"
                  }
                  onChange={onChangeSelect("weekly_commitment")}
                  DefaultOption={
                    "How many hours per week are you willing to spend on personal development?"
                  }
                  error={error.weekly_commitment}
                />

                <SelectInput
                  label="Investment in Development"
                  onChange={onChangeSelect("investment_budget")}
                  name={"investment_budget"}
                  value={formData.investment_budget}
                  numberOptions={price_range}
                  DefaultOption={
                    "What is your budget range for personal development activities?"
                  }
                  error={error.investment_budget}
                />
              </>
            ) : isProgress === 2 ? (
              <>
                <div className="">
                  <div className="flex items-center justify-between mb-2">
                    <div className="text-[#323238] text-lg font-semibold">
                      Experience
                    </div>
                    <div
                      id="popup"
                      onClick={() => {
                        setIsExperiencePopup(true);
                        setExperienceTemp(props.DEFAULT_TEMP_EXP);
                      }}
                      className="text-primary cursor-pointer select-none border rounded-md p-2 text-xs border-primary"
                    >
                      + Add Experience
                    </div>
                  </div>
                  {!!formData.experience.length &&
                    formData.experience.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className=" border border-grayColor rounded-[8px] mt-2 p-3"
                        >
                          <div className="flex gap-5 justify-between">
                            <div className="text-xs">
                              {item?.employer_name?.name}
                            </div>
                            <div className="text-xs">
                              {/* {item.current_job ? item.current_job : ""} */}
                            </div>
                          </div>
                          <div className="flex justify-between gap-5 mt-2">
                            <div className="text-xs">
                              {item?.job_title + " in " + item?.industry}
                            </div>
                            <div className="text-xs whitespace-nowrap">
                              {item?.start_date
                                .replace("-", "/")
                                .replace("-", "/")}{" "}
                              {!item?.current_job && "-"}{" "}
                              {!item?.current_job &&
                                item?.end_date
                                  .replace("-", "/")
                                  .replace("-", "/")}
                            </div>
                          </div>
                          <div className="flex justify-between mt-2">
                            <div className="text-xs w-[400px]">
                              {item?.responsibilities}
                            </div>
                          </div>
                          <div className="flex gap-2 justify-end">
                            <Button
                              id="popup"
                              customClass={
                                "!px-2 !py-1 !text-primary bg-white border border-[#d7d7d7] !text-xs"
                              }
                              onClick={() => handleDeleteExperience(index)}
                              text="Delete"
                            />
                            <Button
                              id="popup"
                              customClass={
                                "!px-2 !py-1 !text-primary bg-white border border-[#d7d7d7] !text-xs"
                              }
                              onClick={() => props.handleEditExperience(index)}
                              text="Edit"
                            />
                          </div>
                        </div>
                      );
                    })}
                  {/* popup experience  */}
                  <ModelWindow
                    custom={
                      "w-full px-5 md:w-[70%] !px-3 !py-3 md:!mb-0 !mb-[36px] !overflow-auto"
                    }
                    open={isExperiencePopup}
                    setOpen={() => {
                      setIsExperiencePopup(false);
                    }}
                  >
                    <ExperienceFrom
                      {...{
                        setIsExperiencePopup,
                        isExperiencePopup,
                        experienceTemp,
                        handleChangeExperience,
                        handleChangeCurrentJob,
                        CountryName,
                        handleCountryName,
                        handleUpdateExperience,
                        handleAddExperience,
                        handleChangeEmployerName,
                        inputLoading,
                        places,
                        handleEmployerName,
                        handleEmployerCountry,
                        countriesData,
                        handleInstituteCountryName,
                        countries,
                        activity_identity,
                        onChangeIndustry,
                      }}
                    />
                  </ModelWindow>
                </div>
              </>
            ) : isProgress === 3 ? (
              // education area
              <>
                <div className="flex items-center justify-between mb-2">
                  <div className="text-[#323238]  text-lg font-semibold">
                    Education
                  </div>
                  <div
                    id="popup"
                    onClick={() => {
                      setIsEducationPopup(true);
                      setEducationTemp(DEFAULT_TEMP_EDUCATION);
                    }}
                    className="text-primary cursor-pointer select-none border rounded-md p-2 text-xs border-primary"
                  >
                    + Add Education
                  </div>
                </div>
                {!!formData?.education?.length &&
                  formData.education.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className=" border border-grayColor rounded-[8px] mt-2 p-3"
                      >
                        <div className="flex justify-between gap-2">
                          <div className="text-xs">
                            {item?.degree !== "None" && item?.degree}
                            {item?.school && ` at ${item?.school}`}
                          </div>
                          <div className="text-xs">
                            {item?.start_date
                              .replace("-", "/")
                              .replace("-", "/")}{" "}
                            {!item?.current_job && ""}{" "}
                            {item?.end_date.replace("-", "/").replace("-", "/")}
                          </div>
                        </div>
                        <div className="text-xs">
                          {item?.school?.name + " , " + item?.others
                            && item?.others
                            + " , " + item?.country?.name &&
                            item?.country?.name}
                        </div>
                        {/* </div> */}
                        <div className="flex justify-between mt-2">
                          <div className="text-xs w-[400px]">{item?.area}</div>
                        </div>
                        <div className="flex gap-2 justify-end">
                          <Button
                            id="popup"
                            customClass={
                              "!px-2 !py-1 !text-primary bg-white border border-[#d7d7d7] !text-xs"
                            }
                            onClick={() => handleDeleteEducation(index)}
                            text="Delete"
                          />
                          <Button
                            id="popup"
                            customClass={
                              "!px-2 !py-1 !text-primary bg-white border border-[#d7d7d7] !text-xs"
                            }
                            onClick={() => handleEditEducation(index)}
                            text="Edit"
                          />
                        </div>
                      </div>
                    );
                  })}
                {/* popup education  */}
                <ModelWindow
                  custom={
                    "!w-[70%] !px-3 !py-3 md:!mb-0 !mb-[36px] !overflow-auto"
                  }
                  open={isEducationPopup}
                  setOpen={() => {
                    setIsEducationPopup(false);
                  }}
                >
                  <IoMdClose
                    onClick={() => setIsEducationPopup(!isEducationPopup)}
                    className="cursor-pointer w-fit ml-auto"
                  />
                  <div id="popup" className="px-32 flex flex-col gap-1">
                    <div>
                      <h1 className="text-center text-2xl pt-5 text-primary">
                        Add Education
                      </h1>
                      <p className="pb-3 text-center  text-sm text-gray-400">
                        Please only list completed higher education
                        qualifications.
                      </p>
                    </div>
                    <SelectInput
                      id="popup"
                      label="Degree"
                      name={"degree"}
                      onChange={onChangeDegree("degree")}
                      numberOptions={DEGREE}
                      value={educationTemp?.degree}
                      DefaultOption={"Select your degree"}
                    />

                    <div className="text-[#0D182A] font-medium text-sm leading-7 tracking-[0.32px]">
                      Institute's Country{" "}
                      <span className="text-red-500 ml-1">*</span>
                    </div>
                    <Select
                      placeholder="Start typing to searching..."
                      options={countries}
                      value={educationTemp?.country}
                      getOptionLabel={(label) => label.name}
                      onChange={handleEducationCountryName}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          minHeight: "8px",
                          margin: "0",
                          padding: "0px !important",
                          outline: "none",
                          fontSize: "12px",
                          borderTop: "0px solid #D0D5DD",
                          borderRight: "0px solid #D0D5DD",
                          borderLeft: "0px solid #D0D5DD",
                          borderBottom: "1px solid #D0D5DD",
                          borderRadius: "0px",
                          "::placeholder": {
                            fontSize: "12px",
                            borderTop: "0px solid #D0D5DD",
                            borderRight: "0px solid #D0D5DD",
                            borderLeft: "0px solid #D0D5DD",
                            borderBottom: "1px solid #D0D5DD",
                            borderRadius: "0px",
                            padding: "0px !important",
                          },
                          ":focus": {
                            borderColor: "black",
                          },
                          boxShadow: "0 !important",
                          "&:hover": {
                            borderTop: "0px solid #D0D5DD",
                            borderRight: "0px solid #D0D5DD",
                            borderLeft: "0px solid #D0D5DD",
                            borderBottom: "1px solid #D0D5DD",
                            borderRadius: "0px",
                          },
                          backgroundColor: state.isFocused ? "transparent" : "transparent",
                          indicatorsContainer: (prevStyle, state) =>
                            state.isMulti
                              ? {
                                ...prevStyle,
                              }
                              : null,
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          color: "#8D8D99",
                          fontSize: "12px",
                        }),
                      }}
                    />
                    {error.educationCountry !== "" && (
                      <Fade duration={900}>
                        <div className="text-xs text-red-500 duration-1000 transition-all">
                          {error.educationCountry}
                        </div>
                      </Fade>
                    )}
                    <TextInput
                      name={"school"}
                      value={educationTemp.school}
                      onChange={handleChangeEducation}
                      label={"School"}
                      placeholder={"Enter your school name"}
                    />
                    {/* <TextInput
                      onInputChange={(txt) => handleChangeName(txt)}
                      isLoading={inputLoading}
                      options={placesOptions}
                      value={educationTemp.school}
                      getOptionLabel={(label) => label.name}
                      onChange={handleSchoolName}
                      loadingMessage={() => "Searching..."}
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          minHeight: "8px",
                          margin: "0",
                          padding: "0px !important",
                          outline: "none",
                          fontSize: "12px",
                          borderTop: "0px solid #D0D5DD",
                          borderRight: "0px solid #D0D5DD",
                          borderLeft: "0px solid #D0D5DD",
                          borderBottom: "1px solid #D0D5DD",
                          borderRadius: "0px",
                          "::placeholder": {
                            fontSize: "12px",
                            padding: "0px !important",
                          },
                          ":focus": {
                            borderColor: "black",
                          },
                          boxShadow: "0 !important",
                          "&:hover": {
                            border: "0 !important",
                            borderBottom: "1px solid #D0D5DD",
                          },

                          backgroundColor: state.isFocused
                            ? "transparent"
                            : "transparent",
                          indicatorsContainer: (prevStyle, state) =>
                            state.isMulti
                              ? {
                                  ...prevStyle,
                                }
                              : null,
                        }),
                      }}
                    /> */}

                    {/*<TextInput*/}
                    {/*  name={"others"}*/}
                    {/*  value={educationTemp?.others}*/}
                    {/*  onChange={handleChangeEducation}*/}
                    {/*  label={"Others"}*/}
                    {/*  placeholder={*/}
                    {/*    "Can't find your school? Enter 'none' in School"*/}
                    {/*  }*/}
                    {/*  */}
                    {/*/>*/}
                    <div className="flex gap-5">
                      <TextInput
                        name={"end_date"}
                        type={"date"}
                        value={educationTemp.end_date}
                        onChange={handleChangeEducation}
                        label={"Date of Completion"}
                      />
                    </div>
                    <TextInput
                      textarea
                      name={"area"}
                      value={educationTemp.area}
                      onChange={handleChangeEducation}
                      label={"Area of Study"}
                      placeholder={"Example: Computer Science"}
                    />
                    <Button
                      id="popup"
                      customClass={"mx-auto mt-2"}
                      text={
                        educationTemp?.index !== undefined ? "Update" : "Submit"
                      }
                      onClick={() =>
                        educationTemp?.index !== undefined
                          ? handleUpdateEducation(educationTemp?.index)
                          : handleAddEducation()
                      }
                    />
                  </div>
                </ModelWindow>
              </>
            ) : isProgress === 4 ? (
              <>
                <SelectInput
                  label="How old are you?"
                  name={"age"}
                  numberOptions={numberOptions}
                  value={formData.age}
                  onChange={onChangeSelect("age")}
                  DefaultOption={"Select your age"}
                  error={error.age}
                />
                <SelectInput
                  label="Your Ethnicity?"
                  name={"ethnicity"}
                  numberOptions={identity}
                  value={formData?.ethnicity}
                  onChange={onChangeSelect("ethnicity")}
                  DefaultOption={"Select your ethnicity"}
                  error={error.ethnicity}
                />
                <SelectInput
                  label="Your gender"
                  name={"gender"}
                  numberOptions={gender}
                  value={formData?.gender}
                  onChange={onChangeSelect("gender")}
                  DefaultOption={"Select your gender"}
                  error={error.gender}
                />
                {/* <SelectInput
                  label="Current Country of Residence"
                  name={"country"}
                  numberOptions={CountryName}
                  defaultValue={formData?.country}
                  onChange={onChangeSelect("country")}
                  DefaultOption={"Select your country"}
                /> */}
                <div>
                  <div className="text-[#0D182A] font-medium text-sm leading-7 tracking-[0.32px]">
                    Current Country of Residence{" "}
                    <span className="text-red-500 ml-1"></span>
                  </div>
                  <Select
                    placeholder="Current Country of Residence"
                    options={countries}
                    isClearable
                    value={formData?.country}
                    getOptionLabel={(label) => label.name}
                    onChange={handleAdditionalCountryName}
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        minHeight: "8px",
                        margin: "0",
                        padding: "0px 10px  !important",
                        outline: "none",
                        fontSize: "12px",
                        fontWeight: "200",
                        borderTop: "1px solid #D0D5DD",
                        borderRight: "1px solid #D0D5DD",
                        borderLeft: "1px solid #D0D5DD",
                        borderBottom: "1px solid #D0D5DD",
                        borderRadius: "100px",
                        "::placeholder": {
                          fontSize: "12px",
                          color: "#000",
                        },
                        ":focus": {
                          borderColor: "black",
                        },
                        boxShadow: "0 !important",
                        backgroundColor: state.isFocused ? "transparent" : "transparent",
                        indicatorsContainer: (prevStyle, state) =>
                          state.isMulti
                            ? {
                                ...prevStyle,
                              }
                            : null,
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: "#8D8D99",
                        fontSize: "12px",
                      }),
                    }}
                  />
                  <Fade duration={900}>
                    <div className="text-xs mt-1 text-red-500 duration-1000 transition-all">
                      {error.country}
                    </div>
                  </Fade>
                </div>
                <SelectInput
                  label="Have you ever served as a member of the Armed Forces of any country?"
                  name={"armed"}
                  numberOptions={ARMED}
                  value={formData?.armed}
                  onChange={onChangeSelect("armed")}
                  DefaultOption={
                    "Please indicate whether you have served in any country's Armed Forces."
                  }
                  error={error.armed}
                />
              </>
            ) : null}
            <FromFooter
              {...{
                handleNext,
                handleBack,
                isProgress,
                totalSteps,
                handleSubmit,
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="mx-5 md:w-[50%] md:mx-auto flex flex-col justify-center items-center h-[70vh]">
            <div className="border shadow-sm rounded-2xl p-10 flex flex-col justify-center items-center gap-10">
              <div className="text-3xl text-center">Disclaimer</div>
              <div className="text-justify text-base font-normal leading-6 gap-5">
                At MyAiDestiny, we ensure your privacy by not storing any of your
                data once your personalized career report is generated.
                Remember, while not all questions in our questionnaire are
                mandatory, providing more detailed responses enables us to
                create a more customized and comprehensive report tailored to
                your career aspirations.
              </div>
              <Button
                onClick={() =>
                  props.setIsDisclaimerAgree(!props.isDisclaimerAgree)
                }
                text="Next"
                customClass={"!px-12 !py-1.5 !text-base !font-medium !h-fit"}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionForm;
