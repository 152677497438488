// import { Link, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "./header";
import { FaHeadphonesAlt } from "react-icons/fa";
import { MdPrivacyTip } from "react-icons/md";
import { HiReceiptRefund } from "react-icons/hi2";

import { IoDocumentAttachOutline } from "react-icons/io5";


const FOOTER_LINKS = [
  {
    icon: <FaHeadphonesAlt size={20} />,
    name: "Contact",
    link: 'mailto:support@myaidestiny.com'

  },
  {
    icon: <MdPrivacyTip size={20} />,
    name: "Privacy Policy",
    link: "https://trycareermapcom.notion.site/CareerMap-Privacy-Policy-a3fa8031f7624ecfb4185d23539e6f2d",
  },
  {
    icon: <HiReceiptRefund size={20} />,
    name: "Terms and Conditions",
    link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
  },
  {
    icon: <IoDocumentAttachOutline size={20} />,
    name: "Refund Policy",
    link: "https://trycareermapcom.notion.site/CareerMap-Terms-Conditions-d43b2ac65cee436894b787f7bf8b9556",
  },
];
const DashboardLayout = ({ children, footer }) => {
  return (
    <div>
      <Header />
      <div className="mx-auto  md:mb-14 md:pb-14">{children}</div>
      {footer && (
        <div className="flex justify-between flex-col md:flex-row items-center gap-2 py-3 px-7 border border-t md:fixed  left-0 right-0 bottom-0 bg-white">
          <div className="text-secondary text-xs">
            ©{new Date().getFullYear()} myaidestiny all rights reserved
          </div>
          <div className="flex items-center gap-2 md:gap-4">
            {FOOTER_LINKS.map((item, index) => {
              return (
                <Link target="_blank" key={index} to={item.link}>
                  <div className="md:text-sm whitespace-nowrap font-medium flex items-center gap-1 py-2 px-2 !text-secondary text-xs">
                    <span className="text-primary">  {item.icon} </span> {item.name}
                  </div>
                </Link>
              );
            })}
          </div>
          {/* <div /> */}
        </div>
      )}
    </div>
  );
};

export default DashboardLayout;
