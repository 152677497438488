import React, { useEffect, useState } from "react";
import QuestionForm from "../components/forms/QuestionForm";
import {
  getAllCountries,
  getDataByDegree,
  getDataByPlace,
  getRelatedStates,
  postDetails,
} from "../services/auth-services";
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import { toast } from "react-toastify";

const steps = ["Ideal Career", "Experience", "Education", "Additional"];
const defaultDataState = {
  // step 1
  name: "",
  ideal_role: "",
  weekly_commitment: "",
  investment_budget: "",
  experience: [],
  education: [],
  age: "",
  ethnicity: "",
  gender: "",
  country: "",
  armed: "",
};
// const DEFAULT_OPTIONS = ["Yes", "No"];
const IDENTITY = [
  "Aboriginal/ Indigenous/ Native",
  "Asian",
  "Black",
  "Hispanic or Latino",
  "Middle Eastern or Arab",
  "Not Specified",
  "Pacific Islander",
  "Two or More Ethnicities",
  "White",
];
const PRICE_RANGES = [
  "$0 - $50",
  "$51 - $100",
  "$101 - $200",
  "$201 - $500",
  "$501 - $1000",
  "$1001 - $2000",
  "$20001+",
];
const DEFAULT_TEMP_EXP = {
  job_title: "",
  start_date: "",
  end_date: "",
  current_job: false,
  employer_country: "",
  employer_city: "",
  responsibilities: "",
  industry: "",
};
const DEFAULT_TEMP_EDUCATION = {
  degree: "",
  school: "",
  start_date: "",
  end_date: "",
  country: "",
  others: "",
  area: "",
};
const GENDER = ["Male", "Female", "Non-binary"];
const ARMED_OPTIONS = [
  "Yes, I am a Veteran",
  "No",
  "I do not wish to answer",
  "Yes, I am a Reservist of a country other than the UK",
];
const ACTIVITY_ARRAY = [
  "Accommodation & Food Service Activities",
  "Administrative & Support Service Activities",
  "Agriculture, Forestry & Fishing",
  "Arts, Entertainment & Recreation",
  "Construction",
  "Education",
  "Electricity, Gas, Steam & Air Conditioning Supply",
  "Financial & Insurance Activities",
  "Human Health & Social Work Activities",
  "Information & Communication",
  "Manufacturing",
  "Mining & Quarrying",
  "Other Service Activities",
  "Professional, Scientific & Technical Activities",
  "Real Estate Activities",
  "Transportation & Storage",
  "Water Supply; Sewerage, Waste Management & Remediation Activities",
  "Wholesale & Retail Trade",
];
const DEGREE_DROP = [
  "Associate's Degree",
  "Bachelor's Degree",
  "Doctorate Degree",
  "Educational Program",
  "High School Diploma/GED",
  "Juris Doctor (J.D.)",
  "Master's Degree",
  "Specialist Degree (e.g., Ed.S., Sp.Ed., Psy.S.)",
  "Technical Diploma",
  "Other",
  "None",
];
const AddDetails = () => {
  const totalSteps = 4;
  const history = useNavigate();
  const [isDisclaimerAgree, setIsDisclaimerAgree] = useState(true);
  const [isProgress, setIsProgress] = useState(1);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultDataState);
  const user = JSON.parse(localStorage.getItem("user"));
  const [countriesData, setCountriesData] = useState([]);
  const [inputLoading, setInputLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [places, setPlace] = useState([]);
  const [place, setPlaces] = useState([]);
  const [degree, setDegree] = useState([]);
  const [isExperiencePopup, setIsExperiencePopup] = useState(false);
  const [isEducationPopup, setIsEducationPopup] = useState(false);
  const [experienceTemp, setExperienceTemp] = useState(DEFAULT_TEMP_EXP);
  const [educationTemp, setEducationTemp] = useState(DEFAULT_TEMP_EDUCATION);
  const [error, setError] = useState({
    name: "",
    location: "",
    ideal_role: "",
    age: "",
    race_identity: "",
    current_location: "",
    current_state: "",
    current_city: "",
    education_level: "",
    institute_name: "",
    course_title: "",
    course_complete_date: "",
    abroad_name: "",
    level_name: "",
    major_specs: "",
    start_date: "",
    end_date: "",
    cfa_level: "",
    master_certificate: "",
  });
  const loadingDuration = 200000; // 120 seconds
  const stepTime = 500; // Update interval in milliseconds
  const [currentPercentage, setCurrentPercentage] = useState(0);

  const startLoading = () => {
    const interval = setInterval(() => {
      setCurrentPercentage((oldPercentage) => {
        let newPercentage = oldPercentage + (stepTime / loadingDuration) * 100;
        if (newPercentage > 99) {
          newPercentage = 99;
          clearInterval(interval);
        }
        return newPercentage;
      });
    }, stepTime);
    // Return the interval ID so it can be cleared outside the function
    return interval;
  };
  const handleValidations = () => {
    if (isProgress === 1) {
      let errors = {}; // Create an empty object to store the errors

      if (formData.name === "") {
        errors.name = "Name is required.";
      }
      if (formData.ideal_role === "") {
        errors.ideal_role = "Ideal role is required.";
      }
      if (formData.weekly_commitment === "") {
        errors.weekly_commitment = "Weekly commits is required.";
      }
      if (formData.investment_budget === "") {
        errors.investment_budget = "Investment Budget is required.";
      }
      setError(errors); // Set the errors in the state

      if (Object.keys(errors).length > 0) {
        return false; // Return false if there are any errors
      }
      return true;
    } else if (isProgress === 2 || isProgress === 3) {
      return true;
    }
  };
  const handleNext = () => {
    if (handleValidations())
      if (isProgress < totalSteps) {
        setIsProgress(isProgress + 1);
        setLoading(false);
      }
  };
  const handleLastStepValidation = () => {
    if (isProgress === 4) {
      let errors = {};
      if (formData.age === "") {
        errors.age = "Age is required";
      }
      if (formData.ethnicity === "") {
        errors.ethnicity = "Ethnicity is required";
      }
      if (formData.gender === "") {
        errors.gender = "Gender is required";
      }
      if (formData.country === "") {
        errors.country = "Country is required";
      }
      if (formData.armed === "") {
        errors.armed = "Armed is required";
      }
      setError(errors);
      if (Object.keys(errors).length > 0) {
        return false;
      }
      return true;
    }
  };

  const handleSubmit = () => {
    if (handleLastStepValidation()) {
      setLoading(true);
      startLoading();
      const body = { form_data: formData, person: formData.name };
      postDetails(body, user.access)
        .then((res) => {
          history(`/report?uuid=${res.data.uuid}`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            Object.keys(errors).forEach((key) => {
              if (Array.isArray(errors[key])) {
                errors[key].forEach((message) => {
                  toast.error(`${message}`);
                });
              } else {
                toast.error(`${errors[key]}`);
              }
            });
          } else {
            toast.error("Network error.");
          }
        });
    }
  };
  const handleChangeExperience = (e) => {
    const { value, name } = e.target;
    setExperienceTemp({ ...experienceTemp, [name]: value });
  };
  const handleChangeEducation = (e) => {
    const { value, name } = e.target;
    setEducationTemp({ ...educationTemp, [name]: value });
  };
  const handleAddExperience = () => {
    const temp = { ...formData };
    temp.experience.push(experienceTemp);
    setFormData(temp);
    setIsExperiencePopup(false);
    setExperienceTemp(DEFAULT_TEMP_EXP);
  };
  const handleAddEducation = () => {
    const temp = { ...formData };
    temp.education.push(educationTemp);
    setFormData(temp);
    setEducationTemp(DEFAULT_TEMP_EDUCATION);
    setIsEducationPopup(false);
  };
  const handleUpdateEducation = (index) => {
    const temp_exp = [...formData.education];
    temp_exp[index] = educationTemp;
    setFormData({ ...formData, education: temp_exp });
    setIsEducationPopup(false);
  };
  const handleEditEducation = (index) => {
    setEducationTemp({ ...formData.education[index], index });
    setIsEducationPopup(true);
  };
  const handleDeleteEducation = (index) => {
    const newData = { ...formData };
    newData.education.splice(index, 1);
    setFormData(newData);
  };

  const handleEditExperience = (index) => {
    setExperienceTemp({ ...formData.experience[index], index });
    setIsExperiencePopup(true);
  };
  const handleUpdateExperience = (index) => {
    const temp_exp = [...formData.experience];
    temp_exp[index] = experienceTemp;
    setFormData({ ...formData, experience: temp_exp });
    setIsExperiencePopup(false);
  };
  const handleDeleteExperience = (index) => {
    const newData = { ...formData }; // Create a copy of the data object
    newData.experience.splice(index, 1); // Remove the object at the specified index
    setFormData(newData); // Update the state with the modified data object
  };
  const handleChangeCurrentJob = (e) => {
    setExperienceTemp({ ...experienceTemp, current_job: e.target.checked });
  };
  const onChangeFields = (e) => {
    const { value, name } = e.target;
    setError({ ...error, [name]: "" });
    if (name === "country") {
      const selectedCountry = countriesData.find(
        (country) => country.country_id === value
      );
      if (selectedCountry) {
        const selectedCountryName = selectedCountry.name;
        setFormData({ ...formData, [name]: selectedCountryName });
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const onChangeSelect = (name) => (val) => {
    setFormData({ ...formData, [name]: val.value });
    setError({ ...error, [name]: "" });
  };
  const onChangeIndustry = (name) => (val) => {
    setExperienceTemp({ ...experienceTemp, [name]: val.value });
  };
  const onChangeDegree = (name) => (val) => {
    setEducationTemp({ ...educationTemp, [name]: val.value });
  };
  const handleBack = () => {
    if (isProgress > 1) {
      setIsProgress(isProgress - 1);
    }
  };
  const numberOptions = Array.from({ length: 82 }, (_, index) => (
    <option key={index + 18} value={index + 18}>
      {index + 18} years old
    </option>
  ));
  const weekly_hours = Array.from({ length: 37 }, (_, index) => (
    <option key={index + 4} value={index + 4}>
      {index + 4}
      {index === 36 && "+"} hours per week
    </option>
  ));
  const identity = IDENTITY.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const activity_identity = ACTIVITY_ARRAY.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const DEGREE = DEGREE_DROP.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const gender = GENDER.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const handleCountryName = (nam) => (val) => {
    setStateData([]);
    setCityData([]);
    const name = nam;
    const value = val;
    setError({ ...error, [name]: "" });
    const selectedCountry = countriesData.find(
      (country) => country.country_id === value
    );
    if (selectedCountry) {
      const selectedCountryId = selectedCountry.country_id;
      const selectedCountryName = selectedCountry.name;
      getRelatedStates([selectedCountryId], user.access)
        .then((res) => {
          setStateData(res.data);
          setExperienceTemp({ ...experienceTemp, [name]: selectedCountryName });
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            Object.keys(errors).forEach((key) => {
              if (Array.isArray(errors[key])) {
                errors[key].forEach((message) => {
                  toast.error(`${message}`);
                });
              } else {
                toast.error(`${errors[key]}`);
              }
            });
          } else {
            toast.error("Network error.");
          }
        });
    }
  };
  const handleCountryEducation = (e) => {
    setCityData([]);
    const { value, name } = e.target;
    setError({ ...error, [name]: "" });
    const selectedCountry = countriesData.find(
      (country) => country.country_id === value
    );
    if (selectedCountry) {
      // const selectedCountryId = selectedCountry.country_id;
      const selectedCountryName = selectedCountry.name;
      setEducationTemp({ ...educationTemp, [name]: selectedCountryName });
    }
  };
  const CountryName = countriesData.map((item, index) => (
    <option key={index + 1} value={item.country_id}>
      {item.name}
    </option>
  ));
  const stateName = stateData.map((item, index) => (
    <option key={index + 1} value={item.state_id}>
      {item.name}
    </option>
  ));
  const handleCityData = (e) => {
    setError({ ...error, [e.target.name]: "" });
    setExperienceTemp({ ...experienceTemp, employer_city: e.target.value });
  };
  const cityName = cityData.map((item, index) => (
    <option key={index + 1} value={item.name}>
      {item.name}
    </option>
  ));
  const price_range = PRICE_RANGES.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const select_hours = Array.from({ length: 61 }, (_, index) => (
    <option key={index + 1} value={index}>
      {index} hours per week
    </option>
  ));
  useEffect(() => {
    getCountriesData();
    // eslint-disable-next-line 
  }, []);
  const getCountriesData = () => {
    getAllCountries(user.access)
      .then((res) => {
        setCountriesData(res.data);
      })
      .catch((error) => { });
  };
  const handleChangeEmployerName = (text) => {
    setInputLoading(true);
    if (text.length > 3) {
      getDataByPlace(text, experienceTemp.employer_country, user.access)
        .then((res) => {
          setInputLoading(false);
          setPlace(res.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            Object.keys(errors).forEach((key) => {
              if (Array.isArray(errors[key])) {
                errors[key].forEach((message) => {
                  toast.error(`${message}`);
                });
              } else {
                toast.error(`${errors[key]}`);
              }
            });
          } else {
            toast.error("Network error.");
          }
        });
    }
    setInputLoading(false);
  };
  const handleChangeName = (text) => {
    setInputLoading(true);
    if (educationTemp.country === "") {
      setError({
        ...error,
        educationCountry:
          "Please select institute country before selecting school.",
      });
      return;
    }
    if (text.length > 3) {
      getDataByPlace(text, educationTemp.country, user.access)
        .then((res) => {
          setInputLoading(false);
          setPlaces(res.data);
        })
        .catch((error) => {
          setInputLoading(false);
        });
    }
    setInputLoading(false);
  };
  const handleInstituteCountryName = (text) => {
    setExperienceTemp({ ...experienceTemp, employer_country: text });
  };
  const handleEducationCountryName = (text) => {
    setError({ ...error, educationCountry: "" });
    setEducationTemp({ ...educationTemp, country: text });
    getDataByDegree(text.name)
      .then((res) => {
        setInputLoading(false);
        const MBAOptionsWithId = res.data.degrees.map((option, index) => ({
          id: index + 1,
          name: option,
        }));
        setDegree(MBAOptionsWithId);
      })
      .catch((error) => {
        setInputLoading(false);
      });
  };
  const handleAdditionalCountryName = (text) => {
    setFormData({ ...formData, country: text });
    setError({ ...error, country: "" });
  };
  const handleChangeDegree = (text) => {
    setInputLoading(true);
    getDataByDegree(text)
      .then((res) => {
        setInputLoading(false);
        const MBAOptionsWithId = res.data.map((option, index) => ({
          id: index + 1,
          name: option.full_name,
        }));
        setDegree(MBAOptionsWithId);
      })
      .catch((error) => {
        setInputLoading(false);
      });
  };
  const ARMED = ARMED_OPTIONS.map((item, index) => (
    <option key={index + 1} value={item}>
      {item}
    </option>
  ));
  const handleEmployerName = (text) => {
    setExperienceTemp({ ...experienceTemp, employer_name: text });
  };
  const handleSchoolName = (text) => {
    setEducationTemp({ ...educationTemp, school: text });
  };
  const handleDegree = (text) => {
    setEducationTemp({ ...educationTemp, degree: text });
  };
  const handleEmployerCountry = (text) => {
    setExperienceTemp({
      ...experienceTemp,
      employer_country: text.props.children,
    });
  };
  return (
    <>
      <Loading {...{ currentPercentage, loading }} />
      <QuestionForm
        {...{
          steps,
          isProgress,
          setIsProgress,
          formData,
          handleBack,
          handleNext,
          numberOptions,
          identity,
          totalSteps,
          handleCountryName,
          CountryName,
          stateName,
          stateData,
          handleCityData,
          cityName,
          cityData,
          price_range,
          select_hours,
          onChangeFields,
          handleSubmit,
          isDisclaimerAgree,
          setIsDisclaimerAgree,
          isExperiencePopup,
          setIsExperiencePopup,
          experienceTemp,
          setExperienceTemp,
          handleChangeExperience,
          handleAddExperience,
          handleDeleteExperience,
          handleEditExperience,
          handleChangeCurrentJob,
          handleUpdateExperience,
          DEFAULT_TEMP_EXP,
          setIsEducationPopup,
          isEducationPopup,
          DEFAULT_TEMP_EDUCATION,
          setEducationTemp,
          educationTemp,
          handleChangeEducation,
          handleUpdateEducation,
          handleAddEducation,
          handleEditEducation,
          handleDeleteEducation,
          handleChangeEmployerName,
          handleChangeName,
          inputLoading,
          places,
          place,
          handleEmployerName,
          handleDegree,
          handleChangeDegree,
          degree,
          handleCountryEducation,
          gender,
          ARMED,
          weekly_hours,
          onChangeSelect,
          handleEmployerCountry,
          countriesData,
          handleInstituteCountryName,
          handleEducationCountryName,
          handleSchoolName,
          handleAdditionalCountryName,
          activity_identity,
          onChangeIndustry,
          onChangeDegree,
          DEGREE,
          error,
        }}
      />
    </>
  );
};

export default AddDetails;
