import React, { useState, useEffect, useRef } from "react";
import { AiFillMessage } from "react-icons/ai";

const Chatbot = () => {
    const [isChatboxOpen, setIsChatboxOpen] = useState(false);
    const [userInput, setUserInput] = useState("");
    const [messages, setMessages] = useState([]);
    const chatboxRef = useRef(null);

    // useEffect(() => {
    //     // Scroll to the bottom of the chatbox when new messages are added
    //     chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    // }, [!messages]);

    const toggleChatbox = () => {
        setIsChatboxOpen(!isChatboxOpen);
    };

    const handleUserInput = (event) => {
        setUserInput(event.target.value);
    };

    const sendMessage = () => {
        if (userInput.trim() !== "") {
            const newMessage = {
                text: userInput,
                isUser: true,
            };
            setMessages([...messages, newMessage]);
            respondToUser(userInput);
            setUserInput("");
        }
    };
    useEffect(() => {
        if (isChatboxOpen) {
            respondToUser("UserMsg")
        }
        return
    }, [isChatboxOpen])
    const respondToUser = (userMessage) => {
        setTimeout(() => {
            const loadingMessage = {
                text: <div class='flex space-x-2 justify-center items-center bg-none '>
                    <span class='sr-only'>Loading...</span>
                    <div class='h-2 w-2 bg-black rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                    <div class='h-2 w-2 bg-black rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                    <div class='h-2 w-2 bg-black rounded-full animate-bounce'></div>
                </div>,
                isUser: false,
            };
            setMessages([...messages, loadingMessage]);
            setTimeout(() => {
                const newMessage = {
                    text: "Sorry for the delay, how else can I assist you today?",
                    isUser: false,
                };
                setMessages([...messages, newMessage]);
            }, 2000); // 5 minutes
        }, 500);
    };

    return (
        <div className="fixed bottom-1 md:bottom-14 right-3 mb-4 rounded-full !z-50">
            <button
                onClick={toggleChatbox}
                className="bg-primary text-white p-2 rounded-full hover:bg-blue-600 transition duration-300 flex items-center"
            >
            <AiFillMessage className="" size={35} />
            </button>
            {isChatboxOpen && (
                <div className="fixed bottom-16 right-4 w-96">
                    <div className="bg-white shadow-md rounded-lg max-w-lg w-full">
                        <div className="p-4 border-b bg-primary text-white rounded-t-lg flex justify-between items-center">
                            <p className="text-lg font-semibold flex items-center gap-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="50px"
                                    height="50px"
                                    viewBox="0 0 1024 1024"
                                    class="icon"
                                    version="1.1"
                                >
                                    <path
                                        d="M213.333333 554.666667m-85.333333 0a85.333333 85.333333 0 1 0 170.666667 0 85.333333 85.333333 0 1 0-170.666667 0Z"
                                        fill="#FFA726"
                                    />
                                    <path
                                        d="M810.666667 554.666667m-85.333334 0a85.333333 85.333333 0 1 0 170.666667 0 85.333333 85.333333 0 1 0-170.666667 0Z"
                                        fill="#FFA726"
                                    />
                                    <path
                                        d="M832 405.333333c0-270.933333-640-177.066667-640 0v213.333334c0 177.066667 142.933333 320 320 320s320-142.933333 320-320V405.333333z"
                                        fill="#FFB74D"
                                    />
                                    <path
                                        d="M512 64C311.466667 64 149.333333 226.133333 149.333333 426.666667v72.533333L192 533.333333v-64l448-209.066666 192 209.066666v64l42.666667-34.133333V426.666667c0-170.666667-121.6-362.666667-362.666667-362.666667z"
                                        fill="#FF5722"
                                    />
                                    <path
                                        d="M661.333333 554.666667m-42.666666 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                                        fill="#784719"
                                    />
                                    <path
                                        d="M362.666667 554.666667m-42.666667 0a42.666667 42.666667 0 1 0 85.333333 0 42.666667 42.666667 0 1 0-85.333333 0Z"
                                        fill="#784719"
                                    />
                                    <path
                                        d="M917.333333 512c-12.8 0-21.333333 8.533333-21.333333 21.333333v-149.333333c0-187.733333-153.6-341.333333-341.333333-341.333333h-149.333334c-12.8 0-21.333333 8.533333-21.333333 21.333333s8.533333 21.333333 21.333333 21.333333h149.333334c164.266667 0 298.666667 134.4 298.666666 298.666667v213.333333c0 12.8 8.533333 21.333333 21.333334 21.333334s21.333333-8.533333 21.333333-21.333334v42.666667c0 83.2-66.133333 149.333333-149.333333 149.333333H512c-12.8 0-21.333333 8.533333-21.333333 21.333334s8.533333 21.333333 21.333333 21.333333h234.666667c106.666667 0 192-85.333333 192-192v-106.666667c0-12.8-8.533333-21.333333-21.333334-21.333333z"
                                        fill="#757575"
                                    />
                                    <path
                                        d="M917.333333 469.333333h-21.333333c-23.466667 0-42.666667 19.2-42.666667 42.666667v85.333333c0 23.466667 19.2 42.666667 42.666667 42.666667h21.333333c23.466667 0 42.666667-19.2 42.666667-42.666667v-85.333333c0-23.466667-19.2-42.666667-42.666667-42.666667z"
                                        fill="#37474F"
                                    />
                                    <path
                                        d="M512 810.666667m-42.666667 0a42.666667 42.666667 0 1 0 85.333334 0 42.666667 42.666667 0 1 0-85.333334 0Z"
                                        fill="#37474F"
                                    />
                                </svg>{" "}
                                Destiny Assistant
                            </p>
                            <button
                                onClick={toggleChatbox}
                                className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6 h-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div ref={chatboxRef} className="p-4 h-80 overflow-y-auto">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`mb-2 ${message.isUser ? "text-right" : ""}`}
                                >
                                    <p
                                        className={`${message.isUser
                                            ? "bg-primary text-white"
                                            : "bg-gray-200 text-gray-700"
                                            } rounded-lg py-2 px-4 inline-block`}
                                    >
                                        {message.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                        <div className="p-4 border-t flex">
                            <input
                                type="text"
                                value={userInput}
                                onChange={handleUserInput}
                                onKeyUp={(event) => {
                                    if (event.key === "Enter") {
                                        sendMessage();
                                    }
                                }}
                                placeholder="Type a message"
                                className="w-full px-3 py-2 border rounded-l-md focus:outline-none focus:ring-2 focus:ring-primary"
                            />
                            <button
                                onClick={sendMessage}
                                className="bg-primary text-white px-4 py-2 rounded-r-md hover:bg-blue-600 transition duration-300"
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
