import React, { useEffect, useState } from "react";
import PasswordInput from "../components/inputs/PasswordInput";
import TextInput from "../components/inputs/TextInput";
import Button from "../components/buttons/DefaultButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ERROR } from "../utils/contants";
import { regexEmail } from "../utils/helpers";
import { toast } from "react-toastify";
import { LogIn, RecordVisitor } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { LinkedIn, googleSvg } from "../components/svg/svg";

const DEFAULT_FIELDS = {
    email: "",
    password: "",
};

const logPageLoad = () => {
    RecordVisitor()
};
const SignIn = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [loading, setLoading] = useState(false);
    const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
    const [cookies, removeCookie] = useCookies(["cookieName"]);
    // Access the value of the cookie
    const refreshToken = cookies.refresh;
    const accessToken = cookies.access;

    useEffect(() => {
        logPageLoad(); // Call this function to log page load
        if (refreshToken !== "undefined") {
            let data = JSON.stringify({ refresh: refreshToken, access: accessToken });
            localStorage.setItem("user", data);
            removeCookie("refresh");
            removeCookie("access");
            // window.location.href = "/";
        }
        // eslint-disable-next-line
    }, []);

    function checkValidations() {
        if (!regexEmail(credentials.email)) {
            toast.warn(ERROR.EMAIL_VALIDATION);
            setLoading(false);
            return false;
        } else if (credentials.password === "") {
            toast.warn(ERROR.EMPTY_PASSWORD);
            setLoading(false);
            return false;
        } else if (credentials.password.length < 8) {
            toast.warn(ERROR.PASSWORD_LENGTH);
            setLoading(false);
            return false;
        }
        return true;
    }

    const handleSignIn = () => {
        setLoading(true);
        if (checkValidations()) {
            LogIn(credentials)
                .then((res) => {
                    let data = JSON.stringify(res.data);
                    localStorage.setItem("user", data);
                    toast.success("Logged In");
                    setLoading(false);
                    // navigate('/dashboard')
                    window.location.href = "/dashboard";
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.response) {
                        const errors = error.response.data;
                        console.log('errors: ', errors);
                        // Object.keys(errors).forEach((key) => {
                        //   if (Array.isArray(errors[key])) {
                        //     errors[key].forEach((message) => {
                        //       toast.error(`${message}`);
                        //     });
                        //   } else {
                        toast.error(errors.error);
                    } else {
                        toast.error("Network error.");
                    }
                });
        }
    };
    const onChangeFields = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };
    useEffect(() => {
        if (location.search.includes('true')) {
            toast.success("Account Verified!")
        }
    }, [location])

    return (
        <div className="bg w-full min-h-screen px-5 flex flex-col justify-center items-center my-auto ">
            <div className="border rounded-2xl  border-none shadow-primaryShadow bg-opacity-20 bg-white backdrop-blur-3xl   p-5 w-full sm:w-[80%] md:w-[60%] lg:w-2/6 flex flex-col gap-4">
                <Link to={"/"}>
                    <img src="/images/site_logo.png" height={80} width={200} className="mx-auto" alt="" />
                </Link>
                <div className="text-xl font-semibold text-black leading-[35px] text-center">
                    Sign In
                </div>
                <TextInput
                    name="email"
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter your email"}
                />
                <PasswordInput
                    name="password"
                    onChange={(e) => onChangeFields(e)}
                    placeholder={"Enter password"}
                />
                <div
                    onClick={() => navigate("/reset-password")}
                    className="text-primary cursor-pointer text-sm text-right"
                >
                    Forgot password?
                </div>
                <Button
                    disabled={loading}
                    text={
                        loading ? (
                            <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
                        ) : (
                            "Continue"
                        )
                    }
                    onClick={handleSignIn}
                    customClass={"!w-full !py-2 !font-normal !text-[12px]"}
                />
                <div className="text-black text-xs  text-center ">
                    New here?{" "}
                    <span
                        onClick={() => navigate("/signup")}
                        className="text-primary cursor-pointer"
                    >
                        Create an account
                    </span>
                </div>
                <div className="flex items-center gap-4">
                    <hr className="w-full  border-primary" />
                    <span className="text-black text-xs">or</span>
                    <hr className="w-full  border-primary" />
                </div>
                <a href={"/api/accounts/google_login/"}>
                    <Button
                        text={"Sign in with google"}
                        customClass={
                            "!w-full !mt-2 !py-2 !font-normal flex-row-revers !text-[12px] !bg-transparent text-black border-borderColor border"
                        }
                    >
                        {googleSvg}
                    </Button>
                </a>
            </div>
        </div>
    );
};

export default SignIn;
