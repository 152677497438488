import React, { useEffect, useState } from "react";
import PlanCard from "../components/cardDesigns/PlanCard";
import { getPrices, paymentSession } from "../services/auth-services";
import DashboardLayout from "../layout/DashboardLayout";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Loading from "../components/loading";
import { Fade, Zoom } from "react-reveal";

const Plans = () => {
  const loadingDuration = 5000; // 2 minutes
  const stepTime = 500; // Update interval in milliseconds
  const user = JSON.parse(localStorage.getItem("user"));
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [plansData, setPlansData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);
  const startLoading = () => {
    const interval = setInterval(() => {
      setCurrentPercentage((oldPercentage) => {
        let newPercentage = oldPercentage + (stepTime / loadingDuration) * 100;
        if (newPercentage > 99) {
          newPercentage = 99;
          clearInterval(interval);
        }
        return newPercentage;
      });
    }, stepTime);
    return interval;
  };
  const handleSession = (index, item) => {
    setPaymentLoading(true);
    startLoading();
    setSelectedPlan(index);
    let body = { price_id: item.id };
    paymentSession(body, user?.access)
      .then((res) => {
        let url = res.data.session_url;
        setPaymentLoading(false);
        window.location.href = url;
      })
      .catch((err) => {
        setPaymentLoading(false);
        toast.error(err.message);
      });
  };

  const getPayments = () => {
    getPrices(user.access)
      .then((res) => {
        setLoading(false);
        setPlansData(res.data);
        console.log('res.data: ', res.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          const errors = error.response.data;
          Object.keys(errors).forEach((key) => {
            if (Array.isArray(errors[key])) {
              errors[key].forEach((message) => {
                toast.error(`${message}`);
              });
            } else {
              toast.error(`${errors[key]}`);
            }
          });
        } else {
          toast.error("Network error.");
        }
      });
  };
  useEffect(() => {
    getPayments();
    // eslint-disable-next-line
  }, []);
  return (
    <DashboardLayout footer>
      <Loading
        text={"planing your career with MyAiDestiny"}
        {...{ currentPercentage }}
        loading={paymentLoading}
      />
      <div className="flex flex-col my-10 pb-5  items-center justify-center gap-6">
        <div className="lg:flex flex-col-reverse gap-">
          <div className="flex flex-col gap-5 w-full">
            <Fade top>
              <div className="pb-5 p-3 text-2xl mt-5 leading-normal tracking-[-.114px] font-semibold text-secondary text-center ">
                Purchase credits below
              </div>
            </Fade>
            <div className="gap-5 flex items-stretch h-full  flex-wrap w-2/2  justify-center  mx-auto">
              {loading ? (
                <>
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                </>
              ) : (
                plansData?.map((item, index) => (
                  <Zoom top duration={index === 1 ? 900 : 1500}>
                    <PlanCard
                      key={index}
                      onClick={() => handleSession(index, item)}
                      plans={true}
                      {...{
                        item,
                        selectedPlan,
                        setSelectedPlan,
                        index,
                        handleSession,
                        paymentLoading,
                      }}
                    />
                  </Zoom>
                ))
              )}
            </div>
          </div>
          {/* <div className="w-1 bg-gray-200 rounded-full mt-1 mx-10" /> */}
          {/* section 2 */}
          <div>
            <Fade top>
              <div className="pb-5 text-2xl leading-normal tracking-[-.114px] font-semibold text-secondary text-center ">
                Monthly subscription
              </div>
            </Fade>
            <div className="gap-5 flex items-center flex-wrap w-2/2 mt-5 justify-center  mx-auto ">
              {loading ? (
                <>
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                  <Skeleton
                    className="!w-[200px] !h-[150px] mt-5 !flex !flex-col "
                    count={1}
                  />
                </>
              ) : (
                plansData?.map((item, index) => (
                  <Zoom top duration={index === 1 ? 900 : 1500}>
                    <PlanCard
                      key={index}
                      onClick={() => handleSession(index, item)}
                      {...{
                        item,
                        selectedPlan,
                        setSelectedPlan,
                        index,
                        handleSession,
                        paymentLoading,
                      }}
                    />
                  </Zoom>
                ))
              )}
            </div>
          </div>


        </div>
      </div>
    </DashboardLayout>
  );
};

export default Plans;
