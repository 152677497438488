import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Fade } from "react-reveal";
const PasswordInput = ({
  label,
  placeholder,
  name,
  onChange,
  id,
  value,
  topSet,
  onKeyDown,
  error,
  onKeyUp,
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <div>

      <div className="relative flex-col flex">
        <input
          id={id}
          name={name}
          value={value}
          onKeyUp={onKeyUp}
          onChange={onChange}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          type={showPass ? "text" : "password"}
          className="outline-none border px-5 rounded-full bg-transparent  focus:bg-white border-grayColor  placeholder:text-xs text-xs font-normal placeholder:text-secondary placeholder:leading-[23.8px] placeholder:tracking-[0.28px] placeholder:font-light leading-4 !text-[#8D8D99] py-3 ${customClass} "
        />
        {showPass ? (
          <AiOutlineEye
            onClick={() => setShowPass(false)}
            className={`absolute right-2 top-0 bottom-0 m-auto cursor-pointer text-primary ${topSet}`}
          />
        ) : (
          <AiOutlineEyeInvisible
            onClick={() => setShowPass(true)}
            className={`absolute right-2 top-0 bottom-0 m-auto cursor-pointer text-primary ${topSet}`}
          />
        )}
      </div>
      {error && (
        <Fade duration={900}>
          <div className="text-xs  text-red-500 mt-1 duration-1000 transition-all">
            {error}
          </div>
        </Fade>
      )}
    </div>
  );
};

export default PasswordInput;