import React, { useEffect, useState } from "react";
import Header from "../layout/header";
import PasswordInput from "../components/inputs/PasswordInput";
import Button from "../components/buttons/DefaultButton";
import { useLocation, useNavigate } from "react-router-dom";
import { ERROR } from "../utils/contants";
import { toast } from "react-toastify";
import { confirmPassword } from "../services/auth-services";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import CONFIRM_PASSWORD from "../entities/Confirm.entity";

const DEFAULT_FIELDS = {
  reset_key: "",
  password1: "",
  password2: "",
};
const ConfirmPassword = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState(DEFAULT_FIELDS);
  const [error, setErrors] = useState({})
  console.log('error: ', error);
  // const checkValidations = () => {
  //   if (credentials.password1 === "") {
  //     toast.warn(ERROR.EMPTY_PASSWORD);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password1.length < 8) {
  //     toast.warn(ERROR.PASSWORD_LENGTH);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password2 === "") {
  //     toast.warn(ERROR.CONFORM_EMPTY);
  //     setLoading(false);
  //     return false;
  //   } else if (credentials.password1 !== credentials.password2) {
  //     toast.warn(ERROR.SAME_ERROR);
  //     setLoading(false);
  //     return false;
  //   }
  //   return true;
  // };
  useEffect(() => {
    setCredentials({
      ...credentials,
      reset_key: location.search.replace("?key=", ""),
    });
    // eslint-disable-next-line
  }, [location]);
  const handleConfirmPassword = async () => {
    try {
      new CONFIRM_PASSWORD({ ...credentials });
      setErrors(null)
      let body = credentials
      body.new_password = credentials.password2
      setLoading(true);
      confirmPassword(body)
        .then((res) => {
          window.location.href = "/signin";
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            const errors = error.response.data;
            Object.keys(errors).forEach((key) => {
              if (Array.isArray(errors[key])) {
                errors[key].forEach((message) => {
                  toast.error(`${message}`);
                });
              } else {
                toast.error(`${errors[key]}`);
              }
            });
          } else {
            toast.error("Network error.");
          }
        });
    } catch (error) {
      console.log('errorss: ', error);
      setErrors(error)
    }
  };
  const onChangeFields = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  return (
    <div className="mt-20/">
      <Header />
      <div className="w-full mt-20 h-[70vh] flex flex-col justify-center items-center m-auto">
        <div className=" w-[400px] flex flex-col gap-4">
          <div className="text-2xl font-semibold leading-[35px] text-center">
            Confirm Password
          </div>
          <PasswordInput
            name={"password1"}
            onChange={(e) => onChangeFields(e)}
            placeholder={"New passwsord"}
            error={error?.password1}
          />
          <PasswordInput
            onChange={(e) => onChangeFields(e)}
            name="password2"
            placeholder={"Confirm password"}
            error={error?.password2}
          />
          <Button
            text={
              loading ? (
                <AiOutlineLoading3Quarters className="text-2xl animate-spin" />
              ) : (
                "Change Password"
              )
            }
            onClick={handleConfirmPassword}
            customClass={"!w-full !mt-2 !py-2 !font-normal !text-[12px]"}
          />
        </div>

        <div className="text-[#515961s text-xs mt-[180px] text-center fixed bottom-5 right-0 left-0">
          New here?{" "}
          <span
            onClick={() => navigate("/signup")}
            className="text-primary cursor-pointer"
          >
            Create an account
          </span>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPassword;
