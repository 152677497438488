import React from "react";
import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";
import Button from "../buttons/DefaultButton";
import SelectInput from "../inputs/SelectInput";

const ExperienceFrom = ({
  setIsExperiencePopup,
  isExperiencePopup,
  experienceTemp,
  handleChangeExperience,
  handleChangeCurrentJob,
  handleUpdateExperience,
  handleAddExperience,
  places,
  activity_identity,
  onChangeIndustry,
}) => {
  return (
    <>
      <IoMdClose
        onClick={() => setIsExperiencePopup(!isExperiencePopup)}
        className="cursor-pointer w-fit ml-auto"
      />
      <div id="popup" className="md:px-32 flex flex-col gap-[6px]">
        <h1 className="text-center text-2xl py-5 text-primary">
          Add Work History
        </h1>
        <SelectInput
          id="popup"
          label="Industry"
          name={"industry"}
          onChange={onChangeIndustry("industry")}
          numberOptions={activity_identity}
          value={experienceTemp?.identity}
          // onChange={handleChangeExperience}
          DefaultOption={"Select Experienced industry"}
        />

        <TextInput
          name={"job_title"}
          placeholder={"Enter your job title?"}
          onChange={handleChangeExperience}
          value={experienceTemp.job_title}
          label={"Job title"}
        />
        <div className="flex gap-5">
          <TextInput
            name={"start_date"}
            value={experienceTemp.start_date}
            onChange={handleChangeExperience}
            label={"Start Date"}
            type={"date"}
          />
          {!experienceTemp.current_job && (
            <TextInput
              name={"end_date"}
              value={experienceTemp.end_date}
              onChange={handleChangeExperience}
              type={"date"}
              label={"End Date"}
            />
          )}
        </div>
        <div className="flex items-center mb-1.5">
          <input
            checked={experienceTemp.current_job}
            id="current_job"
            onChange={(e) => handleChangeCurrentJob(e)}
            name="current_job"
            type="checkbox"
            className="cursor-pointer"
          />
          <label
            htmlFor="current_job"
            className="ml-2  text-primary font-semibold cursor-pointer text-sm"
          >
            Current Job
          </label>
        </div>

        {/* <TextInput
                    name={"employer_city"}
                    value={experienceTemp.employer_city}
                    onChange={handleChangeExperience}
                    label={"Employer City"}
                /> */}
        <TextInput
          textarea
          name={"responsibilities"}
          value={experienceTemp.responsibilities}
          onChange={handleChangeExperience}
          label={"Responsibilities"}
          labelBelow="Please describe your responsibilities in 2-3 sentences."
        />
        <Button
          id="popup"
          customClass={"mx-auto mt-2"}
          text={experienceTemp?.index !== undefined ? "Update" : "Submit"}
          onClick={() =>
            experienceTemp?.index !== undefined
              ? handleUpdateExperience(experienceTemp?.index)
              : handleAddExperience()
          }
        />
        <div className="my-3" />
      </div>
    </>
  );
};

export default ExperienceFrom;
