import React, { useState } from "react";
import SecondHeader from "../layout/SecondHeader";
import Footer from "../layout/Footer";
import { Fade, Slide, Zoom } from "react-reveal";

const Pricing = () => {
    const user = JSON.parse(localStorage.getItem("user"));

    const QUESTIONS = [
        {
            ques: "How can I contact you?",
            ans: `As we're a startup, we only have our email set up but we'll be launching our social media pages soon! In the meantime, you can contact us on account@myaidestiny.com`,
        },
        {
            ques: "How does Myaidestiny tailor career reports to my individual profile?",
            ans: `Myaidestiny utilizes a sophisticated AI algorithm to analyze your demographic information, work experience, educational background, and career goals. By comparing this data against current job market trends, Myaidestiny creates a custom report that includes a career snapshot, transition trajectories, salary specifics, and more. This personalized approach ensures that every piece of advice and recommendation is directly applicable to your unique career path and aspirations.`,
        },
        {
            ques: `Can Myaidestiny assist me if I'm looking to change careers entirely?`,
            ans: `Absolutely! Myaidestiny is designed to support individuals at any stage of their career, including those looking to make a significant change. Through our Transition Trajectories feature, we provide insights into alternative careers that align with your skills and interests, along with a detailed action plan (Blueprint Success) to help you make the transition. We also offer resources like the Learning Ladder and Network Nexus to support your journey toward your new career path.`,
        },
        {
            ques: "Is my information kept confidential?",
            ans: `Yes! We prioritize your privacy. Your data is not sold, shared, or distributed to any third parties. You have the option to request data deletion at any time.`,
        },
        {
            ques: "How up-to-date is the information provided by Myaidestiny?",
            ans: `Myaidestiny's data is continuously updated to reflect the latest market trends, salary information, and job opportunities. Our AI-powered system constantly scans the job market and relevant educational resources to ensure that the information and recommendations you receive are current and accurate. This means you can trust Myaidestiny to provide you with the most up-to-date advice for making informed decisions about your career.`,
        },
    ];
    const [openQuestions, setOpenQuestions] = useState(new Array(QUESTIONS.length).fill(false));

    const handleClick = (index) => {
        // Toggle the state of the clicked question
        setOpenQuestions(openQuestions.map((item, i) => (i === index ? !item : item)));
    };

    return (
        <>
            <SecondHeader user={user} />
            <div className="">
                <Fade top>
                    <div className="text-center mt-12 pb-4 text-[88px] font-semibold leading-[95px]">
                        FAQ
                    </div>
                </Fade>
                <Fade top>
                    <div className="text-xl text-center pb-10 text-[#858694]">
                        Find our most commonly asked questions below
                    </div>
                </Fade>
                <div className="px-5 md:w-1/2 mx-auto flex flex-col gap-6">
                    {QUESTIONS.map((item, index) => {
                        return (
                            <Zoom>
                                <div key={index} className="bg-[#e7ebf2] p-4 rounded-2xl cursor-pointer ">
                                    <div onClick={() => handleClick(index)} className="flex justify-between gap-2 md:gap-20 transition-all duration-1000">
                                        <div className="font-medium text-slate-800">{item.ques}</div>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 256 256"
                                            focusable="false"
                                            className={`rotate- ${openQuestions[index] ? 'rotate-[134deg]' : ''} transition-all duration-300`}

                                            height={20}
                                            width={20}
                                            color="#39b791"
                                            style={{ userSelect: 'none', display: 'inline-block', fill: '#39b791', flexShrink: '0' }}
                                        >
                                            <g color="#39b791" weight="bold">
                                                <line
                                                    x1="40"
                                                    y1="128"
                                                    x2="216"
                                                    y2="128"
                                                    fill="none"
                                                    stroke="#39b791"
                                                    strokeLinejoin="#39b791"
                                                    strokeLinecap="round"
                                                    strokeWidth="24"
                                                ></line>
                                                <line
                                                    x1="128"
                                                    y1="40"
                                                    x2="128"
                                                    y2="216"
                                                    fill="none"
                                                    stroke="#39b791"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="24"
                                                ></line>
                                            </g>
                                        </svg>
                                    </div>
                                    {openQuestions[index] && (
                                        <Fade top duration={500}>
                                            <div className="text-[#717172] mt-5">
                                                {item.ans}
                                            </div>
                                        </Fade>
                                    )}
                                </div>
                            </Zoom>
                        )
                    })}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Pricing;
