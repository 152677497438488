import "./App.css";
import "./load.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import SignIn from "./pages/signin";
import SignUp from "./pages/signup";
import { gapi } from "gapi-script";
import { useEffect } from "react";
import { isAuthenticated } from "./utils/helpers";
import AddDetails from "./pages/questions";
import ResetPassword from "./pages/reset-password";
import ConfirmPassword from "./pages/confirm-password";
import Plans from "./pages/plans";
import AccountSettings from "./pages/account-settings";
import Report from "./pages";
import DashboardIndex from "./pages/dashboard";
import Support from "./pages/support";
import Success from "./pages/success";
import NotFound from "./pages/404";
import "react-loading-skeleton/dist/skeleton.css";
import Reports from "./pages/reports";
import Cancelled from "./pages/cancelled";
import SampleReport from "./pages/sample-report";
import HomePage from '../src/pages/home-page'
import Pricing from "./pages/pricing";
import FAQS from "./pages/faqs";

const App = () => {
  const initializeGapi = () => {
    gapi.client.init({
      clientId:
        "797435745105-nm1g2fhejl6mfh2oh6dbmkqovpp0q6tu.apps.googleusercontent.com",
      scope: "",
    });
  };
  useEffect(() => {
    gapi.load("client:auth2", initializeGapi);
  });
  return (
    <Routes>
      <Route
        path={"/signin"}
        element={isAuthenticated() ? <Navigate to="/" /> : <SignIn />}
      />
      <Route
        path={"/"}
        element={<HomePage />}
      />
      <Route
        path={"/pricing"}
        element={<Pricing />}
      />
      <Route
        path={"/help"}
        element={<FAQS />}
      />
      <Route
        path={"/create-report"}
        element={isAuthenticated() ? <AddDetails /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/payment-cancelled"}
        element={isAuthenticated() ? <Cancelled /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/payment_success"}
        element={isAuthenticated() ? <Success /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/report"}
        element={isAuthenticated() ? <Report /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/sample-reports"}
        element={
          isAuthenticated() ? <SampleReport /> : <Navigate to="/signin" />
        }
      />
      <Route path={"/report-example"} element={<Report />} />

      <Route
        path={"/credits"}
        element={
          isAuthenticated() ? <DashboardIndex /> : <Navigate to="/signin" />
        }
      />
      <Route
        path={"/plans"}
        element={isAuthenticated() ? <Plans /> : <Navigate to="/signin" />}
      />
      <Route
        path={"404"}
        element={isAuthenticated() ? <NotFound /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/dashboard"}
        element={isAuthenticated() ? <Reports /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/support"}
        element={isAuthenticated() ? <Support /> : <Navigate to="/signin" />}
      />
      <Route
        path={"/account-settings"}
        element={
          isAuthenticated() ? <AccountSettings /> : <Navigate to="/signin" />
        }
      />
      <Route
        path={"/signup"}
        element={isAuthenticated() ? <Navigate to="/" /> : <SignUp />}
      />

      <Route
        path={"/reset-password"}
        element={isAuthenticated() ? <Navigate to="/" /> : <ResetPassword />}
      />
      <Route
        path={"/confirm_password"}
        element={isAuthenticated() ? <Navigate to="/" /> : <ConfirmPassword />}
      />
      <Route path="*" element={isAuthenticated() ? <NotFound /> : <Navigate to="/signin" />} />
    </Routes>
  );
};
export default App;
