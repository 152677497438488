import React from "react";
import DashboardLayout from "../layout/DashboardLayout";
import Button from "../components/buttons/DefaultButton";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <DashboardLayout footer>
      <img src="/images/thanku.png" alt="" className="mx-auto mt-[10%]" />
      <div className="text-center text-3xl text-secondary mt-5 font-medium">
        Thank you for purchasing credits!
      </div>
      <div className=" flex justify-center mt-5">
        <Link to="/create-report">
          <Button text={"Create Report"} customClass={"!w-fit"} />
        </Link>
      </div>
    </DashboardLayout>
  );
};

export default Success;
